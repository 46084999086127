import { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { Tooltip } from '@mui/material';

import { CreatorBadge } from '@/components/creatorBadge/creatorBadge';
import { useWindowDimensions } from '@/hooks';
import styles from '@/pages/activities/videosSection/videosSection.module.css';
import { VideoObject } from '@/types';

import { PlayButton } from './playButton';

interface Props {
  video: VideoObject;
  rowLayout?: boolean;
}

export const VideoItem: React.FC<Props> = ({ video, rowLayout }) => {
  const [showOverlays, setShowOverlays] = useState(true);
  const videoFile = video.files.find((file) => file.Type === 6);
  const thumbnailImage = video.files.find((file) => file.Type === 7);
  const { width: windowWidth } = useWindowDimensions();
  const { postCreatorAvatar, postCreatorName } = video;

  const summaryText = useMemo(() => {
    const text = video.summaries?.[0]?.SummaryText || '';

    switch (true) {
      case rowLayout:
        return text.slice(0, 170);
      case windowWidth < 480:
        return text.slice(0, 300);
      case windowWidth < 768:
        return text.slice(0, 450);
      case windowWidth < 1024:
        return text.slice(0, 300);
      case windowWidth < 1440:
        return text.slice(0, 800);
      default:
        return text.slice(0, 320);
    }
  }, [windowWidth, video.summaries, rowLayout]);

  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.playerWrapper}>
        <ReactPlayer
          className={styles.player}
          controls={true}
          height="100%"
          light={
            <img
              alt="Thumbnail"
              className={styles.thumbnailImage}
              height="100%"
              src={thumbnailImage?.Link}
              width="100%"
            />
          }
          onClickPreview={() => setShowOverlays(false)}
          playIcon={<PlayButton />}
          playing={true}
          url={videoFile?.Link}
          width="100%"
        />
        {showOverlays && (
          <>
            <CreatorBadge
              avatar={postCreatorAvatar}
              date={videoFile?.CreateDate || ''}
              name={postCreatorName}
            />
            {summaryText && (
              <div className={styles.summaryWrapper}>
                {video.summaries && summaryText.length < video.summaries[0].SummaryText.length ? (
                  <Tooltip title={video.summaries[0].SummaryText}>
                    <div className={styles.summary}>{summaryText + '...'}</div>
                  </Tooltip>
                ) : (
                  <div className={styles.summary}>{summaryText}</div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
