import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { selectedUserSlice } from '@/store/slices/selectedUserSlice';
import { taskMembersSlice } from '@/store/slices/taskMembersSlice';

import { drawerSlice } from './slices/drawerSlice';
import { messagesSlice } from './slices/messagesSlice';
import { postsSlice } from './slices/postsSlice';
import { selectedActivitySlice } from './slices/selectedActivitySlice';
import { topSectionSlice } from './slices/topSectionSlice';
import { userSettingsSlice } from './slices/userSettingsSlice';
import { userSlice } from './slices/userSlice';
import { api } from './api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  selectedActivity: selectedActivitySlice.reducer,
  selectedUser: selectedUserSlice.reducer,
  posts: postsSlice.reducer,
  user: userSlice.reducer,
  messages: messagesSlice.reducer,
  drawer: drawerSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  topSection: topSectionSlice.reducer,
  taskMembers: taskMembersSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
