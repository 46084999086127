import Avatar from '@mui/material/Avatar';
import { format } from 'date-fns';

import { useAppSelector } from '@/hooks';
import { ActivityPost } from '@/types';

import { ExpandablePanel } from '../expandablePanel';

import styles from './notes.module.css';

interface Props {
  post: ActivityPost;
}

export const Notes: React.FC<Props> = ({ post }) => {
  const postNotes = post.Notes;
  const users = useAppSelector((state) => state.taskMembers);
  const notesWithUserAvatars = postNotes.map((note) => ({
    ...note,
    Creator: {
      ...note.Creator,
      UserAvatar: users.find((user) => user.PersonId === note.Creator.PersonId)?.ImageUrl,
    },
  }));

  return (
    <ExpandablePanel heading="Note" isModeratorPost={post.ThreadCreatorMembershipStatus === 6}>
      {notesWithUserAvatars.map(({ NoteId, Text, CreateDate, Creator }) => (
        <div className={styles.noteCard} key={NoteId}>
          <div className={styles.header}>
            <div className={styles.userContainer}>
              <Avatar alt="Avatar" src={Creator.UserAvatar} sx={{ width: 20, height: 20 }} />
              <div className={styles.userInfo}>
                <div className={styles.userName}>{`${Creator.FirstName} ${Creator.LastName}`}</div>
                <time className={styles.time}>
                  {format(new Date(CreateDate), 'dd.MM.yy HH:mm')}
                </time>
              </div>
            </div>
          </div>
          <div className={styles.text}>{Text}</div>
        </div>
      ))}
    </ExpandablePanel>
  );
};
