import cn from 'classnames';

import styles from './sectionHeaderTab.module.css';

interface Props<T> {
  tab: T;
  isActive: boolean;
  onClick: (tab: T) => void;
  counter?: number;
}

export const SectionHeaderTab = <T extends React.ReactNode>({
  tab,
  isActive,
  onClick,
  counter,
}: Props<T>) => {
  return (
    <div className={styles.tabWrapper}>
      <button
        className={cn(styles.tab, { [styles.tabActive]: isActive })}
        onClick={() => onClick(tab)}
      >
        {tab}
      </button>
      {!!counter && <div className={styles.unreadCount}>{counter}</div>}
    </div>
  );
};
