import { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton } from '@mui/material';

import { TabButton } from '@/components/activityDetailsDrawer/tabButton';
import { SidebarTab } from '@/types';

import styles from './sidebarDrawer.module.css';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  tabs: SidebarTab[];
}

export const SidebarDrawer: React.FC<Props> = ({ title, isOpen, onClose, tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  const content = useMemo(
    () => tabs.find(({ title }) => title === activeTab)?.content,
    [activeTab, tabs],
  );

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={isOpen}
      PaperProps={{ square: false, className: styles.drawer }}
    >
      <header className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.tabs}>
          {tabs.map(({ title, icon }) => (
            <TabButton
              icon={icon}
              isActive={activeTab === title}
              key={title}
              onClick={() => setActiveTab(title)}
              title={title}
            />
          ))}
        </div>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon sx={{ stroke: 'black', strokeWidth: 2 }} />
        </IconButton>
      </header>
      <main className={styles.content}>{content}</main>
    </Drawer>
  );
};
