import React from 'react';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg';
import { NumberInCircle } from '@/components/numberInCircle';
import { useAppSelector, useWindowDimensions } from '@/hooks';
import { useUserData } from '@/hooks/useUserData';
import { PostUserTags } from '@/pages/activities/activityPosts/tags/postUserTags';
import { User } from '@/types';
import { scrollToTop } from '@/utils/scrollToTop';

import styles from './peopleItem.module.css';

interface Props {
  user: User;
  onUserSelect: (user: User) => void;
  selectedUserId: string;
}
export const PeopleItem: React.FC<Props> = ({ user, onUserSelect, selectedUserId }) => {
  const { completeCount, notCompleteCount, engagementTime, lastPostTime, timeTooltip } =
    useUserData(user);
  const { width: windowWidth } = useWindowDimensions();
  const isSelected = user.PersonId === selectedUserId;
  const heroImageIsShown = useAppSelector(
    (state) => state.userSettings.discussions?.HeroImageIsShown,
  );

  const handlePersonClick = () => {
    onUserSelect(user);
    scrollToTop(heroImageIsShown ? 400 : 60);
  };

  /*
  const statusDots: StatusDots = {
    1: { color: 'blue', tooltip: 'Completed' },
    2: { color: 'red', tooltip: 'Unanswered' },
    3: { color: 'orange', tooltip: 'Not Visible Yet' },
    4: { color: 'grey', tooltip: 'Not Shown' },
  };
  */

  return (
    <div
      className={cn(styles.container, { [styles.selectedUser]: isSelected })}
      onClick={handlePersonClick}
    >
      <div className={styles.userCard}>
        <div className={styles.userCardHeader}>
          <div className={styles.avatarWrapper}>
            <Avatar
              alt="Avatar"
              className={styles.personAvatar}
              src={user.ImageUrl}
              variant="square"
            />
            {!!user.UnreadPosts && (
              <div className={styles.unreadPosts}>
                <Tooltip title="Unread posts">
                  <div>
                    <NumberInCircle
                      backgroundColor="#e22929"
                      borderColor="#fff"
                      number={user.UnreadPosts}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={styles.userInfoWrapper}>
            <div className={styles.userInfo}>
              <div className={styles.userInfoHeader}>
                <div className={styles.userName}>{user.DisplayName}</div>
                <div className={styles.userStats}>
                  <Tooltip title="Completed activities">
                    <div>
                      <NumberInCircle
                        backgroundColor="#258E61"
                        borderColor="#eff0f5"
                        number={completeCount}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="Not completed activities">
                    <div>
                      <NumberInCircle
                        backgroundColor="#e22929"
                        borderColor="#eff0f5"
                        number={notCompleteCount}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              {engagementTime || lastPostTime ? (
                <Tooltip title={windowWidth < 768 ? timeTooltip : ''}>
                  <div className={styles.time}>
                    {engagementTime && (
                      <div
                        className={styles.engagementTime}
                      >{`Engaged for ${engagementTime}.`}</div>
                    )}
                    {lastPostTime && (
                      <div className={styles.lastPostTime}>{`Last post: ${lastPostTime}.`}</div>
                    )}
                  </div>
                </Tooltip>
              ) : (
                <div className={styles.noData}>No time data for this user</div>
              )}
            </div>
            <Tooltip title="Show user details">
              <IconButton className={styles.userOverviewBtn}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={styles.userCardFooter}>
          <div className={styles.postsCount}>
            {`Posts: `}
            <span className={styles.postsCountNumber}>{user.TotalPosts}</span>
          </div>
          <PostUserTags userId={user.PersonId} />
        </div>
      </div>

      {/*DO NOT delete this code*/}

      {/*<div className={styles.statusBar}>
        {user.TopicCompletionData?.map((circleDot) => (
          <Tooltip
            key={circleDot.TopicId}
            title={statusDots[circleDot.Status as keyof StatusDots].tooltip}
          >
            <div
              className={styles.statusItem}
              style={{
                backgroundColor: statusDots[circleDot.Status as keyof StatusDots].color,
              }}
            />
          </Tooltip>
        ))}
      </div>*/}
    </div>
  );
};
