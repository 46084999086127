import { useMemo } from 'react';

import { Loading } from '@/components/loading';
import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery } from '@/store/api';
import { Activity } from '@/types';
import { purifyHtml } from '@/utils/purifyHtml';

interface Props {
  activity: Activity;
}

export const InstructionsTab: React.FC<Props> = ({ activity }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = activity.DiscussionTopicId;
  const { data: activityDetails, isFetching: isActivityDetailsFetching } =
    useGetActivityDetailsQuery({ discussionId, activityId });

  const instructions = useMemo(() => {
    if (!activityDetails) {
      return '';
    }
    return purifyHtml(activityDetails[0].ThoroughDescription, { allowedAttr: ['style', 'src'] });
  }, [activityDetails]);

  return (
    <>
      {isActivityDetailsFetching ? (
        <Loading />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: instructions }} />
      )}
    </>
  );
};
