import { useMemo } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/hooks';
import { VideoObject } from '@/types';

import { VideoItem } from './videoItem';

import styles from './videosSection.module.css';

interface Props {
  rowLayout?: boolean;
}

export const VideosSection: React.FC<Props> = ({ rowLayout }) => {
  const posts = useAppSelector((state) => state.posts);

  const videos = useMemo(() => {
    const videosList: VideoObject[] = [];
    posts.forEach((post) => {
      const postCreatorName = post.ThreadCreatorName;
      const postCreatorAvatar = post.ThreadCreatorUserImage;
      post.Attachments.forEach((attachment) => {
        const attachmentId = attachment.AttachmentId;
        const summaries = attachment.Summaries;
        if ([1, 13].includes(attachment.FileType) && attachment.Files.length) {
          videosList.push({
            attachmentId,
            postCreatorName,
            postCreatorAvatar,
            summaries,
            files: attachment.Files,
          });
        }
      });
    });
    return videosList;
  }, [posts]);

  return (
    <div className={cn(styles.videosWrapper, { [styles.rowLayout]: rowLayout })}>
      {videos.map((video) => {
        return <VideoItem key={video.attachmentId} rowLayout={rowLayout} video={video} />;
      })}
    </div>
  );
};
