import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { AccessDenied } from '@/components/accessDenied';
import { Layout } from '@/components/layout';
import { Loading } from '@/components/loading';
import { NoMatch } from '@/components/noMatch';
import { Activities } from '@/pages/activities';
import { People } from '@/pages/people';
import { session } from '@/utils/session';

export const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessId = searchParams.get('id');
    if (accessId) {
      session.accessId.set(accessId);
    }
    if (!session.accessId.get()) {
      navigate('/access-denied', { replace: true });
    }
    setLoading(false);
  }, [navigate, searchParams, setSearchParams]);

  if (loading) {
    return <Loading overContent={true} />;
  }

  return (
    <Routes>
      <Route element={<Layout />} path="/">
        {/*<Route index element={<Recent />} />*/}
        {/*<Route path="recent" element={<Recent />} />*/}
        <Route element={<Activities />} index />
        <Route element={<Activities />} path="activities" />
        <Route element={<People />} path="people" />
      </Route>

      <Route element={<AccessDenied />} path="access-denied" />
      <Route element={<NoMatch />} path="*"></Route>
    </Routes>
  );
};
