import { useMemo } from 'react';
import cn from 'classnames';

import { Activity } from '@/types';

import { ActivityGroup } from './activityGroup';

import styles from './activityList.module.css';

interface Props {
  activityLayout: number;
  data: Activity[];
  onActivitySelect: (id: string) => void;
  selectedId: string;
  rowLayout: boolean;
}

interface Group {
  id: string;
  title: string;
  topics: Activity[];
}

export const ActivityList: React.FC<Props> = ({
  activityLayout,
  data,
  onActivitySelect,
  selectedId,
  rowLayout,
}) => {
  const topicGroups = useMemo(() => {
    const [groups, topics] = data.reduce<[Group[], Activity[]]>(
      (acc, topic) => {
        if (topic.IsGroup) {
          acc[0].push({
            id: topic.DiscussionTopicId,
            title: topic.Title,
            topics: [],
          });
        } else {
          acc[1].push(topic);
        }
        return acc;
      },
      [[], []],
    );

    const topicsWithoutGroup: Activity[] = [];

    topics.forEach((topic) => {
      const group = groups.find(({ id }) => id === topic.GroupId);
      if (group) {
        group.topics.push(topic);
      } else {
        topicsWithoutGroup.push(topic);
      }
    });

    if (topicsWithoutGroup.length) {
      groups.push({
        id: 'other',
        title: 'Other',
        topics: topicsWithoutGroup,
      });
    }

    return groups;
  }, [data]);

  return (
    <div className={cn(styles.list, { [styles.rowList]: rowLayout })}>
      {topicGroups.map((group) => (
        <ActivityGroup
          activityLayout={activityLayout}
          defaultOpen
          items={group.topics}
          key={group.id}
          onActivitySelect={onActivitySelect}
          rowLayout={rowLayout}
          selectedId={selectedId}
          title={group.title}
        />
      ))}
    </div>
  );
};
