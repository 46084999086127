import React, { useMemo } from 'react';

import { Theme } from '@/types';

import styles from './themeRowItem.module.css';

interface Props {
  isChecked: boolean;
  theme: Theme;
  onSelect: (theme: Theme) => void;
}

export const ThemeRowItem: React.FC<Props> = ({ isChecked, theme, onSelect }) => {
  const themeName = useMemo(() => {
    const name = theme.Name;
    return name.length > 25 ? `${name.slice(0, 10)}...${name.slice(-10)}` : name;
  }, [theme.Name]);

  return (
    <label className={styles.themeLabel}>
      <div className={styles.themeNameWrapper}>
        <span className={styles.themeName} title={theme.Name}>
          {themeName}
        </span>
      </div>
      <input
        checked={isChecked}
        className={styles.checkbox}
        onChange={() => onSelect(theme)}
        type="checkbox"
      />
      <div className={styles.customCheckbox}></div>
    </label>
  );
};
