import { NewTag, Tag } from '@/types';
import { filterHiddenTags } from '@/utils/filterHiddenTags';

import { api } from '.';

interface RecentTagsArgs {
  discussionId: string;
  context: string;
}

interface CreateNewTagArgs {
  discussionId: string;
  body: NewTag;
}

export const {
  useGetRecentTagsQuery,
  useGetSearchTagsQuery,
  useAddPersonTagMutation,
  useAddContentTagMutation,
  useDeletePersonTagMutation,
  useDeleteContentTagMutation,
  useCreateNewTagMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getRecentTags: builder.query<Tag[], RecentTagsArgs>({
      query: ({ discussionId, context }) => ({
        url: `v3/discussions/${discussionId}/recentTags/${context}`,
        method: 'GET',
      }),
      transformResponse: filterHiddenTags,
    }),
    getSearchTags: builder.query<Tag[], RecentTagsArgs & { searchInput: string }>({
      query: ({ discussionId, context, searchInput }) => ({
        url: `v3/discussions/${discussionId}/tags/${context}`,
        params: {
          search: searchInput,
        },
        method: 'GET',
      }),
      transformResponse: filterHiddenTags,
    }),
    addPersonTag: builder.mutation({
      query: ({ discussionId, TaskMemberId, TagId }) => ({
        url: `v3/discussions/${discussionId}/taskmembertags`,
        method: 'PUT',
        body: { TaskMemberId, TagId },
      }),
    }),
    addContentTag: builder.mutation({
      query: ({ discussionId, ThreadId, TagId }) => ({
        url: `v3/discussions/${discussionId}/threadtags`,
        method: 'PUT',
        body: { ThreadId, TagId },
      }),
    }),
    deletePersonTag: builder.mutation({
      query: ({ discussionId, TaskMemberId, TagId }) => ({
        url: `v3/discussions/${discussionId}/taskmembertags`,
        method: 'DELETE',
        body: { TaskMemberId, TagId },
      }),
    }),
    deleteContentTag: builder.mutation({
      query: ({ discussionId, ThreadId, TagId }) => ({
        url: `v3/discussions/${discussionId}/threadtags`,
        method: 'DELETE',
        body: { ThreadId, TagId },
      }),
    }),
    createNewTag: builder.mutation<Tag, CreateNewTagArgs>({
      query: ({ discussionId, body }) => ({
        url: `v3/discussions/${discussionId}/tags`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
