import { useCallback } from 'react';

import { ActivityPost, PostHighlight, SuggestedHighlight } from '@/types';

import { ExpandablePanel } from '../expandablePanel';

import { SuggestedHighlightCard } from './suggestedHighlightCard';

interface Props {
  post: ActivityPost;
  suggestedHighlights: SuggestedHighlight[];
  setSuggestedHighlights: React.Dispatch<React.SetStateAction<SuggestedHighlight[]>>;
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const SuggestedHighlights: React.FC<Props> = ({
  post,
  suggestedHighlights,
  setSuggestedHighlights,
  setPostHighlights,
}) => {
  const onDeleteHandler = useCallback(
    (suggestedHighlight: SuggestedHighlight) => {
      setSuggestedHighlights((prev) =>
        prev.filter(({ SuggestionId }) => SuggestionId !== suggestedHighlight.SuggestionId),
      );
    },
    [setSuggestedHighlights],
  );

  const onCreateHandler = useCallback(
    (newHighlight: PostHighlight) => {
      setPostHighlights((prev) => [newHighlight, ...prev]);
    },
    [setPostHighlights],
  );

  return (
    <ExpandablePanel
      heading="Suggested Highlights"
      isModeratorPost={post.ThreadCreatorMembershipStatus === 6}
    >
      {suggestedHighlights.map((suggestedHighlight) => (
        <SuggestedHighlightCard
          key={suggestedHighlight.SuggestionId}
          onCreate={onCreateHandler}
          onDelete={onDeleteHandler}
          post={post}
          suggestedHighlight={suggestedHighlight}
        />
      ))}
    </ExpandablePanel>
  );
};
