import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as Logo } from '@/assets/logo.svg';
import { Badge } from '@/components/badge';
import { ProjectInfoBtn } from '@/components/header/projectInfoBtn';
import { useAppSelector } from '@/hooks';

import { Statistics } from './statistics';

import styles from './header.module.css';

interface Props {
  onLoad: () => void;
}

export const Header: React.FC<Props> = ({ onLoad }) => {
  const unreadMessages = useAppSelector((state) => state.messages.unread);
  const user = useAppSelector((state) => state.user);
  const discussions = useAppSelector((state) => state.userSettings.discussions);
  const projectName = discussions?.Title;
  const isShowHeaderImage = discussions?.HeroImageIsShown;
  const textColor = isShowHeaderImage ? '#FFF' : '#21171F';
  const isShowHeaderOverlay = !!discussions?.HeroImageOverlayType;

  const backgroundImage = isShowHeaderImage
    ? { backgroundImage: `url(${discussions?.HeroImageUrl})` }
    : {};

  return (
    <header
      className={cn(styles.header, {
        [styles.showHeaderBackground]: isShowHeaderImage,
      })}
      style={backgroundImage}
    >
      <img
        alt="header background"
        onError={onLoad}
        onLoad={onLoad}
        src={discussions?.HeroImageUrl}
        style={{ display: 'none' }}
      />

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.projectInfo}>
            <Link to={'/'}>
              <Logo className={styles.logo} color={textColor} />
            </Link>
            <div className={styles.verticalDivider} style={{ backgroundColor: textColor }} />
            <div className={styles.projectName} style={{ color: textColor }} title={projectName}>
              {projectName}
            </div>
          </div>
          <div className={styles.info}>
            <ProjectInfoBtn />
            <div className={styles.badgeWrapper}>
              <Badge
                iconColor={isShowHeaderImage ? '#fff' : '#1c1616'}
                number={unreadMessages}
                outlined
                type="comment"
              />
            </div>
            <div className={styles.profile}>
              <div className={styles.profileAvatarContainer}>
                <Avatar alt="Avatar" className={styles.avatar} src={user.ImageURL} />
              </div>
              <div className={styles.profileName}>{user.FullName}</div>
            </div>
          </div>
        </div>
      </div>
      {isShowHeaderImage && isShowHeaderOverlay && (
        <Statistics layoutVariant={discussions?.HeroImageOverlayType} />
      )}
    </header>
  );
};
