import { ActivityPost, MemberCount } from '@/types';

import { api } from '.';

interface userPostsArgs {
  discussionId: string;
  personId: string;
}

export const { useGetMemberCountsQuery, useGetUserPostsQuery } = api.injectEndpoints({
  endpoints: (builder) => ({
    getMemberCounts: builder.query<MemberCount[], string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/membercounts`,
        method: 'GET',
      }),
    }),
    getUserPosts: builder.query<ActivityPost[], userPostsArgs>({
      query: ({ discussionId, personId }) => ({
        url: `v3/discussions/${discussionId}/people/${personId}/threads`,
        method: 'GET',
      }),
    }),
  }),
});
