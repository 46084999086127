import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Avatar from '@mui/material/Avatar';

import { ReactComponent as WidgetsIcon } from '@/assets/icons/widgets.svg';
import { Loading } from '@/components/loading';
import { SectionHeaderTab } from '@/components/sectionHeaderTab';
import { useActions, useAppSelector } from '@/hooks';
import { HighlightCard } from '@/pages/activities/activityPosts/expandablePanels/highlights/highlightCard';
import { useGetActivityHighlightsQuery } from '@/store/api/highlights';
import { PersonsWithHighlights, PostHighlight, ThemesWithHighlights } from '@/types';

import styles from './highlightsSection.module.css';

const errorMessage = 'An error occurred while requesting highlights';

export enum headerTab {
  Person = 'By Person',
  Theme = 'By Theme',
}

interface Props {
  isPeople?: boolean;
}

export const HighlightsSection: React.FC<Props> = ({ isPeople }) => {
  const actions = useActions();
  const [activeTab, setActiveTab] = useState(headerTab.Person);
  const [highlights, setHighlights] = useState<PostHighlight[]>([]);
  const posts = useAppSelector((state) => state.posts);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = useAppSelector((state) => state.selectedActivity.id);

  const {
    data: highlightsData,
    isFetching,
    isError,
  } = useGetActivityHighlightsQuery({ discussionId, activityId }, { skip: isPeople });

  useEffect(() => {
    if (highlightsData && !isPeople) {
      setHighlights(highlightsData);
    } else {
      const userHighlights = posts.reduce((highlights: PostHighlight[], post) => {
        return [...highlights, ...post.Highlights];
      }, []);
      setHighlights(userHighlights);
    }
  }, [actions, isPeople, highlightsData, posts]);

  const formHighlightsByPerson = useCallback(() => {
    // Create an object to store highlights grouped by ThreadCreatorId
    const highlightsMap: Record<string, PostHighlight[]> = {};

    // Iterate through the highlights and group them by ThreadCreatorId
    highlights.forEach((highlight) => {
      const threadId = highlight.ThreadId;
      if (!highlightsMap[threadId]) {
        highlightsMap[threadId] = [];
      }
      highlightsMap[threadId].push(highlight);
    });

    // Create an array of Persons with sorted highlights
    const highlightsByPerson = posts.map((post) => ({
      id: post.ThreadCreatorId,
      name: post.ThreadCreatorName,
      image: post.ThreadCreatorUserImage,
      highlights: highlightsMap[post.TopicThreadId] || [],
    }));

    // Filter persons without highlights & Merge Persons with the same id
    return highlightsByPerson.reduce<PersonsWithHighlights[]>((acc, item) => {
      if (item.highlights.length) {
        const existingPerson = acc.find((existing) => existing.id === item.id);
        if (existingPerson) {
          existingPerson.highlights.push(...item.highlights);
        } else {
          acc.push(item);
        }
      }
      return acc;
    }, []);
  }, [posts, highlights]);

  const formHighlightsByTheme = useCallback(() => {
    const highlightsWithCreator = highlights
      .map((highlight) => {
        const post = posts.find((post) => post.TopicThreadId === highlight.ThreadId);
        const creator = {
          creatorAvatar: post?.ThreadCreatorUserImage,
          creatorName: post?.ThreadCreatorName,
        };
        return { ...highlight, ...creator };
      })
      .toSorted((a, b) => new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime());

    const themesWithHighlights: ThemesWithHighlights[] = [];
    const highlightsWithoutThemes = highlightsWithCreator.filter(
      (highlight) => !highlight.DiscussionThemes.length,
    );

    highlightsWithCreator.forEach((highlight) => {
      highlight.DiscussionThemes.forEach((theme) => {
        const existingTheme = themesWithHighlights.find(
          (existing) => existing.id === theme.DiscussionThemeId,
        );
        if (existingTheme) {
          existingTheme.highlights.push(highlight);
        } else {
          themesWithHighlights.push({
            id: theme.DiscussionThemeId,
            name: theme.Name,
            highlights: [highlight],
          });
        }
      });
    });

    if (highlightsWithoutThemes.length) {
      themesWithHighlights.push({
        id: 'No Theme Selected',
        name: 'No Theme Selected',
        highlights: highlightsWithoutThemes,
      });
    }

    return themesWithHighlights;
  }, [posts, highlights]);

  const highlightsArray = useMemo<ThemesWithHighlights[] | PersonsWithHighlights[]>(() => {
    if (activeTab === headerTab.Theme) {
      return formHighlightsByTheme();
    }
    return formHighlightsByPerson();
  }, [activeTab, formHighlightsByPerson, formHighlightsByTheme]);

  return (
    <>
      <div className={styles.highlightsHeader}>
        {[headerTab.Person, headerTab.Theme].map((tab) => (
          <SectionHeaderTab
            isActive={tab === activeTab}
            key={tab}
            onClick={setActiveTab}
            tab={tab}
          />
        ))}
      </div>
      <div className={styles.content}>
        {isFetching && <Loading />}
        {!isError ? (
          highlightsArray.map((item) => (
            <div className={styles.personGroup} key={item.id}>
              <div className={styles.userContainer}>
                {activeTab === headerTab.Person && 'image' in item ? (
                  <Avatar alt="Avatar" className={styles.userImage} src={item.image} />
                ) : (
                  <div className={styles.themeIcon}>
                    <WidgetsIcon />
                  </div>
                )}

                <div className={styles.userInfo}>
                  <div className={styles.userName}>{item.name}</div>
                  <div className={styles.highlightsCount}>
                    {`${item.highlights.length} highlight${item.highlights.length > 1 ? 's' : ''}`}
                  </div>
                </div>
              </div>

              <div className={styles.highlightsList}>
                {item.highlights.map((highlight: PostHighlight) => (
                  <HighlightCard
                    activeTab={activeTab}
                    highlight={highlight}
                    key={highlight.HighlightId}
                    setPostHighlights={setHighlights}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <div>{errorMessage}</div>
        )}
      </div>
    </>
  );
};
