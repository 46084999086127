import { RefObject, useEffect, useMemo, useState } from 'react';
import { AddCircleOutline, Error } from '@mui/icons-material';
import { Button } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as CheckmarkIcon } from '@/assets/icons/checkMark.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';
import { SearchInput } from '@/components/searchInput/searchInput';
import { Select } from '@/components/select';
import { useAppSelector, useClickOutside, useEscapeKey } from '@/hooks';
import { TagRowItem } from '@/pages/activities/activityPosts/tags/tagRowItem';
import {
  useAddContentTagMutation,
  useAddPersonTagMutation,
  useCreateNewTagMutation,
  useDeleteContentTagMutation,
  useDeletePersonTagMutation,
  useGetRecentTagsQuery,
  useGetSearchTagsQuery,
} from '@/store/api/tags';
import { PostTagsContext, Tag } from '@/types';

import styles from './tagsModal.module.css';

enum TagsTab {
  Recent,
  Selected,
}

const tagsTabs = [
  { tab: TagsTab.Recent, label: 'Recently Used' },
  { tab: TagsTab.Selected, label: 'Selected' },
];

interface Props {
  tags: Tag[];
  isOpen: boolean;
  context: PostTagsContext;
  apiId: string;
  onTagChange: (tag: Tag, checked: boolean) => void;
  onClose: () => void;
  modalTriggerBtnRef: RefObject<HTMLElement>;
  isEditHighlightTags?: boolean;
}

export const TagsModal: React.FC<Props> = ({
  tags,
  apiId,
  context,
  isOpen,
  onTagChange,
  onClose,
  modalTriggerBtnRef,
  isEditHighlightTags,
}) => {
  const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [tagVisibility, setTagVisibility] = useState(-1);
  const [selectedColor, setSelectedColor] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [createTag, { isError }] = useCreateNewTagMutation();
  const [createTagErrorText, setCreateTagErrorText] = useState('');
  const [formState, setFormState] = useState({
    isTagNameValid: true,
    isTagVisibilityValid: true,
    isTagColorValid: true,
  });

  const [activeTab, setActiveTab] = useState(TagsTab.Selected);
  const { data: recentTags } = useGetRecentTagsQuery({ discussionId, context }, { skip: !isOpen });
  const { data: searchTags } = useGetSearchTagsQuery(
    { discussionId, context, searchInput },
    { skip: !searchInput },
  );
  const [addPersonTag] = useAddPersonTagMutation();
  const [addContentTag] = useAddContentTagMutation();
  const [deletePersonTag] = useDeletePersonTagMutation();
  const [deleteContentTag] = useDeleteContentTagMutation();

  const isSearchResults = !!searchTags?.length;

  const displayedTags = useMemo(
    () =>
      ({
        [TagsTab.Recent]: recentTags?.filter(
          (tag) => !tags.find(({ ContentTagId }) => ContentTagId === tag.ContentTagId),
        ),
        [TagsTab.Selected]: tags,
      })[activeTab] || [],
    [recentTags, activeTab, tags],
  );

  const clickOutsideHandler = (event: MouseEvent) => {
    if (modalTriggerBtnRef.current?.contains(event.target as Node)) {
      return;
    }
    onClose();
  };

  const modalRef = useClickOutside(clickOutsideHandler);

  useEscapeKey(onClose);

  const tagColors = useMemo(() => {
    return ['#B44C9F', '#C2543E', '#D4892C', '#D8BF3A', '#54AD41', '#59ADBC', '#577BDD', '#8B3AE1'];
  }, []);

  useEffect(() => {
    setSearchInput('');
    setSelectedColor('');
    setTagVisibility(-1);
    setFormState((prev) => ({
      ...prev,
      isTagColorValid: true,
      isTagNameValid: true,
      isTagVisibilityValid: true,
      errorText: '',
    }));
  }, [isCreateTagModalOpen]);

  const handleOpenCreateTagModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsCreateTagModalOpen(true);
  };

  useEffect(() => {
    setIsCreateTagModalOpen(false);
  }, [isOpen]);

  const handleCreateNewTag = (event: React.MouseEvent) => {
    setNewTagName(searchInput);
    handleOpenCreateTagModal(event);
  };

  const handleCancelCreateTag = () => {
    setIsCreateTagModalOpen(false);
    setNewTagName('');
  };

  const handleNewTagName = (tagName: string) => {
    setNewTagName(tagName);
    setFormState((prev) => ({ ...prev, isTagNameValid: !!tagName }));
  };

  const handleNewTagVisibility = (value: number) => {
    setTagVisibility(value);
    setFormState((prev) => ({ ...prev, isTagVisibilityValid: value !== -1 }));
  };

  const handleSelectColor = (color: string) => {
    if (selectedColor === color) {
      setSelectedColor('');
    } else {
      setSelectedColor(color);
      setFormState((prev) => ({ ...prev, isTagColorValid: !!color }));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedColor || !newTagName || tagVisibility === -1) {
      setFormState((prev) => ({
        ...prev,
        isTagNameValid: !!newTagName,
        isTagVisibilityValid: tagVisibility !== -1,
        isTagColorValid: !!selectedColor,
      }));
      return;
    }

    const response = await createTag({
      discussionId,
      body: {
        SpecialType: 0,
        TagColor: selectedColor,
        TagValue: newTagName,
        Visibility: tagVisibility,
      },
    });

    if ('data' in response) {
      onTagChange(response.data, true);

      const TagId = response.data.ContentTagId;

      if (context === 'thread') {
        addContentTag({ discussionId, ThreadId: apiId, TagId });
      }

      if (context === 'participants') {
        addPersonTag({
          discussionId,
          TaskMemberId: apiId,
          TagId,
        });
      }
      setSearchInput('');
      onClose();
    } else {
      if ('data' in response.error) {
        setCreateTagErrorText(response.error.data as string);
      }
    }
  };

  const toggleTagHandler = (tag: Tag, checked: boolean) => {
    onTagChange(tag, checked);

    if (checked) {
      if (context === 'thread') {
        addContentTag({ discussionId, ThreadId: apiId, TagId: tag.ContentTagId });
      }
      if (context === 'participants') {
        addPersonTag({ discussionId, TaskMemberId: apiId, TagId: tag.ContentTagId });
      }
    } else {
      if (context === 'thread') {
        deleteContentTag({ discussionId, ThreadId: apiId, TagId: tag.ContentTagId });
      }
      if (context === 'participants') {
        deletePersonTag({ discussionId, TaskMemberId: apiId, TagId: tag.ContentTagId });
      }
    }
  };

  return (
    isOpen && (
      <div
        className={cn(styles.tagsModal, { [styles.tagsModalEdit]: isEditHighlightTags })}
        ref={modalRef}
      >
        {!isCreateTagModalOpen && (
          <>
            <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>Tags</div>
              <Button className={styles.addTagButton} onClick={handleOpenCreateTagModal}>
                <AddCircleOutline className={styles.buttonIcon} />
              </Button>
            </div>
            <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
            {!searchInput && (
              <>
                <div className={styles.tabsContainer}>
                  {tagsTabs.map(({ tab, label }) => (
                    <div
                      className={cn(styles.tagsGroupTitle, styles.tagsTab, {
                        [styles.tagsTabActive]: activeTab === tab,
                      })}
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                    >
                      {label}
                    </div>
                  ))}
                </div>
                {displayedTags.length ? (
                  <div className={styles.tagList}>
                    {displayedTags.map((tag) => (
                      <TagRowItem
                        checked={tags.some(({ ContentTagId }) => ContentTagId === tag.ContentTagId)}
                        key={tag.ContentTagId}
                        onToggle={toggleTagHandler}
                        tag={tag}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles.noResults}>No tags matching this criteria</div>
                )}
              </>
            )}
            {searchInput && (
              <>
                {isSearchResults && (
                  <>
                    <div className={styles.tagsGroupTitle}>Search Results</div>
                    <div className={styles.tagList}>
                      {searchTags.map((tag: Tag) => (
                        <TagRowItem
                          checked={tags.some(
                            ({ ContentTagId }) => ContentTagId === tag.ContentTagId,
                          )}
                          key={tag.ContentTagId}
                          onToggle={toggleTagHandler}
                          tag={tag}
                        />
                      ))}
                    </div>
                  </>
                )}
                {!isSearchResults && (
                  <>
                    <div className={styles.noResults}>
                      <SearchIcon className={styles.noResultsIcon} />
                      No Results
                    </div>
                    <Button className={styles.createTagButton} onClick={handleCreateNewTag}>
                      {`Create “${searchInput}”`}
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        )}
        {isCreateTagModalOpen && (
          <>
            <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>Create New Tag</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputTitle}>Tag Name</div>
                <input
                  className={styles.input}
                  onChange={(event) => handleNewTagName(event.target.value)}
                  type="text"
                  value={newTagName}
                />
                {!formState.isTagNameValid && (
                  <div className={styles.requiredField}>
                    <Error className={styles.errorIcon} />
                    required
                  </div>
                )}
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.inputTitle}>Access</div>
                <Select
                  className={styles.accessSelect}
                  onChange={handleNewTagVisibility}
                  options={[
                    { label: 'Hidden', value: 0 },
                    { label: 'Administrators Only', value: 1 },
                    { label: 'Everyone', value: 2 },
                  ]}
                  placeholder="Select Access..."
                  value={tagVisibility}
                />
                {!formState.isTagVisibilityValid && (
                  <div className={styles.requiredField}>
                    <Error className={styles.errorIcon} />
                    required
                  </div>
                )}
              </div>

              <div className={styles.inputWrapper}>
                <div className={styles.inputTitle}>Tag Color</div>
                <div className={styles.colorsWrapper}>
                  {tagColors.map((color) => (
                    <button
                      className={cn(styles.colorButton, {
                        [styles.selectedColor]: selectedColor === color,
                      })}
                      key={color}
                      onClick={() => handleSelectColor(color)}
                      style={{
                        backgroundColor: color,
                        outlineColor: `${selectedColor === color ? color : 'transparent'}`,
                      }}
                      type="button"
                    >
                      {selectedColor === color && (
                        <CheckmarkIcon className={styles.checkMarkIcon} />
                      )}
                    </button>
                  ))}
                </div>
                {!formState.isTagColorValid && (
                  <div className={styles.requiredField}>
                    <Error className={styles.errorIcon} />
                    required
                  </div>
                )}
                {isError && (
                  <div className={styles.requiredField}>
                    <Error className={styles.errorIcon} />
                    {createTagErrorText}
                  </div>
                )}
              </div>

              <div className={styles.modalControls}>
                <Button className={styles.button} type="submit" variant="contained">
                  Create
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleCancelCreateTag}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    )
  );
};
