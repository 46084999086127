import { ReactComponent as ImagesIcon } from '@/assets/icons/images.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen.svg';
import { ReactComponent as PostsIcon } from '@/assets/icons/posts.svg';
import { ReactComponent as VideosIcon } from '@/assets/icons/videos.svg';
import { ActivitySection } from '@/types';

export const activitySectionIconsMap = {
  [ActivitySection.Posts]: <PostsIcon />,
  [ActivitySection.Highlights]: <PenIcon />,
  [ActivitySection.Images]: <ImagesIcon />,
  [ActivitySection.Videos]: <VideosIcon />,
} as const;
