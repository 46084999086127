import { Children, useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import cn from 'classnames';

import styles from './expandablePanel.module.css';

interface Props {
  heading: string;
  isModeratorPost: boolean;
  children?: React.ReactNode;
}

export const ExpandablePanel: React.FC<Props> = ({ heading, isModeratorPost, children }) => {
  const count = Children.count(children);
  const [expanded, setExpanded] = useState(false);
  const togglePanel = () => setExpanded((prev) => !prev);

  useEffect(() => {
    if (count === 0) {
      setExpanded(false);
    }
  }, [count]);

  return (
    <div className={cn({ [styles.moderatorPost]: isModeratorPost })}>
      <div className={styles.panelTrigger} onClick={togglePanel}>
        <div className={styles.circle}>
          <ChevronRightIcon
            className={cn(styles.circleArrow, { [styles.circleArrowExpanded]: expanded })}
          />
        </div>
        <div className={styles.heading}>{heading}</div>
        {!!count && <div className={styles.circle}>{count}</div>}
      </div>
      <Collapse
        classes={{
          wrapperInner: styles.contentList,
        }}
        in={expanded}
      >
        {children}
      </Collapse>
    </div>
  );
};
