import { useState } from 'react';
import { Button } from '@mui/material';

import { NumberInCircle } from '@/components/numberInCircle';
import { FilterButton } from '@/pages/people/peopleList/filterButton';
import { MemberCount } from '@/types';

import styles from './filterUsersDropdown.module.css';

interface Props {
  memberCounts: MemberCount[];
  allMembersCount: number;
  onSelect: (memberCount: MemberCount | null) => void;
}

export const FilterUsersDropdown: React.FC<Props> = ({
  memberCounts,
  allMembersCount,
  onSelect,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className={styles.filterUsersDropdown}>
      <Button
        className={styles.filterButton}
        onClick={() => onSelect(null)}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        All
        <NumberInCircle
          backgroundColor={'#eff0f5'}
          backgroundColorHover={'#258e61'}
          borderColor={'#eff0f5'}
          borderColorHover={'#fff'}
          color={'#000'}
          colorHover={'#fff'}
          isHovering={isHovering}
          number={allMembersCount}
        />
      </Button>
      {memberCounts.map((memberCount) => (
        <FilterButton key={memberCount.Id} memberCount={memberCount} onSelect={onSelect} />
      ))}
    </div>
  );
};
