import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrowDown.svg';
import { Loading } from '@/components/loading';
import { NumberInCircle } from '@/components/numberInCircle';
import { PopupWrapper } from '@/components/popupWrapper';
import { useAppSelector } from '@/hooks';
import { FilterUsersDropdown } from '@/pages/people/peopleList/filterUsersDropdown';
import { useGetMemberCountsQuery } from '@/store/api/people';
import { MemberCount, User } from '@/types';

import { PeopleItem } from './peopleItem';

import styles from './peopleList.module.css';

interface Props {
  isLoading: boolean;
  memberCount: null | MemberCount;
  users: User[];
  onUserSelect: (user: User) => void;
  onUsersFilter: (memberCount: MemberCount | null) => void;
  selectedUserId: string;
}

export const PeopleList: React.FC<Props> = ({
  isLoading,
  memberCount,
  users,
  onUserSelect,
  onUsersFilter,
  selectedUserId,
}) => {
  const filterUsersBtnRef = useRef<HTMLButtonElement>(null);
  const [showFilteringDropdown, setShowFilteringPopup] = useState(false);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: memberCounts } = useGetMemberCountsQuery(discussionId, {
    skip: !discussionId,
  });

  const allMembersCount = useMemo(() => {
    return (
      memberCounts?.reduce((accum, item) => {
        return accum + item.Count;
      }, 0) || 0
    );
  }, [memberCounts]);

  const handleUsersFilter = (memberCount: MemberCount | null) => {
    if (memberCount?.Count === 0) {
      return;
    }

    onUsersFilter(memberCount);
    setShowFilteringPopup(false);
  };

  return (
    <div className={cn(styles.container, { [styles.expandContainer]: showFilteringDropdown })}>
      <div className={styles.filterWrapper}>
        <button
          className={styles.filterUsersBtn}
          onClick={() => setShowFilteringPopup((prev) => !prev)}
          ref={filterUsersBtnRef}
        >
          {memberCount === null ? 'All people' : memberCount.Id}
          <NumberInCircle
            backgroundColor={'#258e61'}
            borderColor={'#fff'}
            number={memberCount ? memberCount.Count : allMembersCount}
          />
          <ArrowDownIcon
            className={cn(styles.buttonArrowIcon, {
              [styles.buttonArrowIconActive]: showFilteringDropdown,
            })}
          />
        </button>
        {showFilteringDropdown && memberCounts && (
          <PopupWrapper
            className={styles.modalWrapper}
            onClose={() => setShowFilteringPopup(false)}
            outsideTriggerBtnRef={filterUsersBtnRef}
          >
            <FilterUsersDropdown
              allMembersCount={allMembersCount}
              memberCounts={memberCounts}
              onSelect={handleUsersFilter}
            />
          </PopupWrapper>
        )}
      </div>
      <div className={styles.userList}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {users.map((user) => (
              <PeopleItem
                key={user.PersonId}
                onUserSelect={onUserSelect}
                selectedUserId={selectedUserId}
                user={user}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
