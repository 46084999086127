import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DiscussionsResponse, UserSettings } from '@/types';

interface UserSettingsState extends UserSettings {
  discussions?: DiscussionsResponse;
}

const initialState: UserSettingsState = {
  AccountId: '',
  DiscussionId: '',
  PersonToken: '',
  TaskMemberRole: 0,
  discussions: undefined,
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setUserSettings(state, action: PayloadAction<UserSettings>) {
      return { ...state, ...action.payload };
    },
    setDiscussions(state, action: PayloadAction<DiscussionsResponse>) {
      state.discussions = action.payload;
    },
  },
});
