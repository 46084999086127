import { createSlice } from '@reduxjs/toolkit';

import { CurrentUser } from '@/types';

const initialState: CurrentUser = {
  AccountId: '',
  AgreedToTermsAndConditions: true,
  AnonymousName: '',
  CanBeContacted: true,
  CanBeSentSMS: true,
  CreateDate: '',
  EmailAddress: '',
  FirstName: '',
  FullName: '',
  ImageURL: '',
  IsAdmin: true,
  IsRegistrationIncomplete: true,
  IsTwoFactorAuthVerified: true,
  LastName: '',
  Nickname: '',
  PersonId: '',
  TaskMemberCode: '',
  TaskStatus: 0,
  Token: '',
  Verified: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return action.payload;
    },
  },
});
