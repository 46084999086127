export const NOTIFICATION_DURATION = 6000;

export const userRoles: Readonly<Record<number, string>> = {
  0: 'Unknown',
  1: 'Participant',
  4: 'Complete',
  6: 'Moderator',
  7: 'Observer',
};

export const REPLY_SUGGESTIONS_COLLAPSE_DURATION = 600;
