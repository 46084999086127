import { useEffect, useState } from 'react';

import { Attachment } from '@/types';

interface ImageDimensions {
  src: string;
  width: number;
  height: number;
}

const getImageDimensions = (url: string): Promise<ImageDimensions> => {
  return new Promise<ImageDimensions>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve({ src: img.src, width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject(new Error(`Failed to load image: ${url}`));
    };
  });
};

export const useImages = (imageAttachments: Attachment[]) => {
  const [images, setImages] = useState<ImageDimensions[]>([]);

  useEffect(() => {
    const imageLinks = imageAttachments.map((image) => image.Files[0].Link);

    Promise.all(imageLinks.map(getImageDimensions))
      .then((imageDimensions) => {
        setImages(imageDimensions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [imageAttachments]);

  return images;
};
