import { useMemo } from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import styles from '@/components/header/statistics.module.css';

interface Props {
  color: string;
  difference?: number;
  layoutVariant: number;
  number: number;
  title: string;
}

export const StatisticsItem: React.FC<Props> = ({
  color,
  difference,
  layoutVariant,
  number,
  title,
}) => {
  const actionName = useMemo(() => {
    return (
      {
        Invited: 'clicked',
        Joined: 'posted',
        Started: 'completed',
      }[title] || null
    );
  }, [title]);

  return (
    <div className={styles.statisticsCard}>
      <div className={cn(styles.statisticsHeader, styles[`headerIcon${color}`])}></div>
      <div className={styles.statisticInfo}>
        <div className={cn(styles.action, styles[`header${color}`])}>{title}</div>
        <div className={styles.flexWrapper}>
          <div className={styles.actionNumber}>{number}</div>
          {difference !== undefined && (
            <div className={styles.actionInfo}>
              <span className={styles.people}>
                {`${difference} people `}
                {[1, 2].includes(layoutVariant || 0) && <ArrowIcon className={styles.arrowIcon} />}
              </span>
              {`haven't ${actionName}`}
              {[3, 4].includes(layoutVariant || 0) && <ArrowIcon className={styles.arrowIcon} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
