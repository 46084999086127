import { PlayCircleOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import styles from './playButton.module.css';

export const PlayButton: React.FC = () => {
  return (
    <IconButton className={styles.playButton}>
      <PlayCircleOutline className={styles.playButtonIcon} />
    </IconButton>
  );
};
