import { useState } from 'react';
import { Gallery } from 'react-grid-gallery';

import { useImages, useWindowDimensions } from '@/hooks';
import { Attachment } from '@/types';

import { AttachedImage } from './attachedImage';
import { ImageCarousel } from './imageCarousel';

import styles from './imagesGallery.module.css';

interface Props {
  imageAttachments: Attachment[];
}

export const ImagesGallery: React.FC<Props> = ({ imageAttachments }) => {
  const { width: windowWidth } = useWindowDimensions();
  const galleryRowHeight = windowWidth >= 1440 ? 200 : 160;

  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const images = useImages(imageAttachments);

  const handleImageClick = (index: number) => setSelectedImageIndex(index);

  return (
    <>
      <div className={styles.imagesGalleryContainer}>
        <Gallery
          enableImageSelection={false}
          images={images}
          onClick={handleImageClick}
          rowHeight={galleryRowHeight}
          thumbnailImageComponent={AttachedImage}
        />
      </div>
      <ImageCarousel
        images={imageAttachments.map(({ Files }) => Files[0].Link)}
        onClose={() => setSelectedImageIndex(null)}
        selectedImageIndex={selectedImageIndex}
      />
    </>
  );
};
