import { useMemo } from 'react';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { ReactComponent as InfoFileIcon } from '@/assets/icons/info-file.svg';
import { ReactComponent as ResearchIcon } from '@/assets/icons/research.svg';
import { SidebarDrawer } from '@/components/sidebarDrawer';
import { Activity, SidebarTab } from '@/types';

import { InstructionsTab } from './tabs/instructionsTab';
import { StatisticsTab } from './tabs/statisticsTab';
import { SummaryTab } from './tabs/summaryTab';

interface Props {
  activity: Activity;
  isOpen: boolean;
  onClose: () => void;
}

export const ActivityDetailsDrawer: React.FC<Props> = ({ activity, isOpen, onClose }) => {
  const sidebarTabs: SidebarTab[] = useMemo(
    () => [
      {
        title: 'Summary',
        icon: <ResearchIcon />,
        content: <SummaryTab />,
      },
      {
        title: 'Statistics',
        icon: <EqualizerIcon />,
        content: <StatisticsTab activity={activity} />,
      },
      {
        title: 'Instructions',
        icon: <InfoFileIcon />,
        content: <InstructionsTab activity={activity} />,
      },
    ],
    [activity],
  );

  return (
    <SidebarDrawer isOpen={isOpen} onClose={onClose} tabs={sidebarTabs} title={activity.Title} />
  );
};
