import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Collapse } from '@mui/material';
import cn from 'classnames';

import { Activity } from '@/types';

import { ActivityItem } from './activityItem';

import styles from './activityList.module.css';

interface Props {
  activityLayout: number;
  defaultOpen?: boolean;
  items: Activity[];
  onActivitySelect: (id: string) => void;
  selectedId: string;
  title: string;
  rowLayout: boolean;
}

export const ActivityGroup: React.FC<Props> = ({
  activityLayout,
  defaultOpen = false,
  items,
  onActivitySelect,
  selectedId,
  title,
  rowLayout,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleGroup = () => {
    setIsOpen((prevState: boolean) => !prevState);
  };

  return (
    <div className={cn(styles.group, { [styles.rowLayoutGroup]: rowLayout })}>
      {!rowLayout && (
        <>
          <div
            className={cn(styles.groupHeader, { [styles.groupHeaderOpened]: isOpen })}
            onClick={toggleGroup}
          >
            <h2 className={styles.groupHeaderText}>{title}</h2>
            <ArrowForwardIosIcon className={styles.groupHeaderIcon} fontSize="inherit" />
          </div>
          <Collapse in={isOpen}>
            <div className={styles.groupList}>
              {items.map((item) => (
                <ActivityItem
                  activity={item}
                  isSelected={selectedId === item.DiscussionTopicId}
                  key={item.DiscussionTopicId}
                  layout={activityLayout}
                  onSelect={onActivitySelect}
                  rowLayout={rowLayout}
                />
              ))}
            </div>
          </Collapse>
        </>
      )}

      {rowLayout && (
        <>
          <div className={styles.groupTitle}>{title}</div>
          <div className={styles.groupList}>
            {items.map((item) => (
              <ActivityItem
                activity={item}
                isSelected={selectedId === item.DiscussionTopicId}
                key={item.DiscussionTopicId}
                layout={activityLayout}
                onSelect={onActivitySelect}
                rowLayout={rowLayout}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
