import { useState } from 'react';
import { Equalizer as EqualizerIcon, ManageSearch as ManageSearchIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { ReactComponent as InfoIcon } from '@/assets/icons/projectInfo.svg';
import { SidebarDrawer } from '@/components/sidebarDrawer';
import { SidebarTab } from '@/types';

import { OverviewTab } from './overviewTab';
import { StatisticsTab } from './statisticsTab';

import styles from '../header.module.css';

const title = 'About Project';

const sidebarTabs: SidebarTab[] = [
  {
    title: 'Overview',
    icon: <ManageSearchIcon />,
    content: <OverviewTab />,
  },
  {
    title: 'Statistics',
    icon: <EqualizerIcon />,
    content: <StatisticsTab />,
  },
];

export const ProjectInfoBtn = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Tooltip title={title}>
        <IconButton className={styles.badgeWrapper} onClick={() => setIsDrawerOpen(true)}>
          <InfoIcon />
        </IconButton>
      </Tooltip>

      <SidebarDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        tabs={sidebarTabs}
        title={title}
      />
    </>
  );
};
