import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { DeleteOutline, Edit, Feedback, MoreVertSharp, PushPin } from '@mui/icons-material';
import { Alert, Avatar, Button, IconButton, Popover, Snackbar, Tooltip } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';

import { DeleteModal } from '@/components/deleteModal';
import { Loading } from '@/components/loading';
import { ThreadForm } from '@/components/threadForm';
import { NOTIFICATION_DURATION, REPLY_SUGGESTIONS_COLLAPSE_DURATION } from '@/constants';
import { useActions, useAppSelector } from '@/hooks';
import {
  useDeletePostMutation,
  useEditActivityPostMutation,
  useMarkAsReadPostMutation,
  useTogglePinActivityPostMutation,
  useToggleResponseMandatoryMutation,
} from '@/store/api';
import { useGetPostRepliesQuery, useSendReplyMutation } from '@/store/api/replies';
import { Activity, ActivityPost, PostHighlight, ThreadData } from '@/types';

import { ActivityIcon } from '../activityList/activityIcon';

import { PostContentTags } from './tags/postContentTags';
import { PostUserTags } from './tags/postUserTags';
import { ActivityComment } from './activityComment';
import { ExpandablePanels } from './expandablePanels';
import { FormActivityPost } from './formActivityPost';
import { ReplySuggestions } from './replySuggestions';
import { SocialActivityPost } from './socialActivityPost';

import styles from './activityCard.module.css';

interface Props {
  activities?: Activity[];
  post: ActivityPost;
  rowLayout?: boolean;
}

export const ActivityCard: React.FC<Props> = ({ activities, post, rowLayout = false }) => {
  const actions = useActions();
  const [comments, setComments] = useState<ActivityPost[]>([]);
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [controlsAnchorEl, setControlsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);
  const [isChatRepliesLoading, setIsChatRepliesLoading] = useState(false);
  const loggedUser = useAppSelector((state) => state.user);
  const isOpenExpandablePanels = useAppSelector((state) => state.topSection.isOpenExpandablePanels);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { id: activityId, type: activityType } = useAppSelector((state) => state.selectedActivity);
  const [deletePost, { isLoading: isPostDeleting }] = useDeletePostMutation();
  const [togglePin] = useTogglePinActivityPostMutation();
  const [markAsRead] = useMarkAsReadPostMutation();
  const [toggleResponseMandatory] = useToggleResponseMandatoryMutation();

  const [postRef, inView] = useInView({ triggerOnce: true });
  const [postHighlights, setPostHighlights] = useState<PostHighlight[]>(post.Highlights);

  const { data: commentsRes, isFetching } = useGetPostRepliesQuery(
    { discussionId, threadId: post.TopicThreadId },
    { skip: !inView },
  );
  const isModeratorOrObserver = loggedUser.TaskStatus === 6 || loggedUser.TaskStatus === 7;

  useEffect(() => {
    if (!commentsRes) {
      return;
    }
    setComments(
      commentsRes
        .slice()
        .sort(
          (commentA: ActivityPost, commentB: ActivityPost) =>
            Number(new Date(commentA.CreateDate)) - Number(new Date(commentB.CreateDate)),
        ),
    );
  }, [commentsRes]);

  const [sendReply] = useSendReplyMutation();
  const [editPost, { isLoading: isPostUpdating }] = useEditActivityPostMutation();

  const handleSend = async ({ text, attachments, mentionInfo }: ThreadData) => {
    const body = {
      ThreadContent: text.trim(),
      Attachments: attachments,
      Visibility: 0,
      VisibleToId: '',
      VisibleToFullName: '',
    };

    if (mentionInfo) {
      body.Visibility = 1;
    }

    if (mentionInfo?.userId) {
      body.VisibleToId = mentionInfo.userId;
      body.VisibleToFullName = mentionInfo.userName;
    }

    if (!mentionInfo || mentionInfo?.userId) {
      setIsRepliesLoading(true);
    } else {
      setIsChatRepliesLoading(true);
    }

    const res = await sendReply({
      discussionId,
      activityId,
      threadId: post.TopicThreadId,
      body,
    });

    if ('data' in res) {
      setComments((comments) => [...comments, res.data]);
      setIsRepliesLoading(false);
      setIsChatRepliesLoading(false);
    } else {
      console.error(res.error);
      setShowErrorNotification(true);
    }
  };

  const handlePostEdit = async ({ text, attachments }: ThreadData) => {
    const res = await editPost({
      body: {
        ...post,
        ThreadContent: text,
        Attachments: attachments,
      },
      discussionId,
      activityId,
      threadId: post.TopicThreadId,
    });

    if ('data' in res) {
      actions.updatePost(res.data);
      setEditText('');
      setEdit(false);
    } else {
      console.error(res.error);
      setShowErrorNotification(true);
    }
  };

  const handleCommentEdit = useCallback(
    (comment: ActivityPost, remove?: boolean) => {
      setComments((prev) => {
        if (remove) {
          return prev.filter((item) => item.TopicThreadId !== comment.TopicThreadId);
        }
        return prev.map((item) => (item.TopicThreadId === comment.TopicThreadId ? comment : item));
      });
    },
    [setComments],
  );

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
    closeControlsPopover();
  };

  const toggleResponseMandatoryHandler = async () => {
    const newValue = !post.IsResponseMandatory;
    await toggleResponseMandatory({
      discussionId,
      threadId: post.TopicThreadId,
      IsMandatory: newValue,
    });
    actions.updatePost({ ...post, IsResponseMandatory: newValue });
    closeControlsPopover();
  };

  const togglePinHandler = async () => {
    const newValue = !post.IsPinned;
    await togglePin({ threadId: post.TopicThreadId, discussionId });
    actions.updatePost({ ...post, IsPinned: newValue });
  };

  const handleErrorNotificationClose = () => {
    setShowErrorNotification(false);
  };

  const openControlsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setControlsAnchorEl(event.currentTarget);
  };

  const closePostEdit = () => {
    setEdit(false);
  };

  const closeControlsPopover = () => {
    setControlsAnchorEl(null);
  };

  const handleMarkAsRead = (threadId: string) => {
    markAsRead({ discussionId, threadId });
  };

  const activity = activities
    ? activities.find((activity) => activity.DiscussionTopicId === post.DiscussionTopicId)
    : undefined;

  const isModerator = post.ThreadCreatorMembershipStatus === 6;

  const summaryText = useMemo(() => post.Summaries?.[0]?.SummaryText, [post]);
  const { mainComments, chatComments } = useMemo(() => {
    const mainComments: ActivityPost[] = [];
    const chatComments: ActivityPost[] = [];
    comments.forEach((comment) => {
      if (!comment.Visibility || comment.VisibleToId) {
        mainComments.push(comment);
      }
      if (comment.Visibility && !comment.VisibleToId) {
        chatComments.push(comment);
      }
    });
    return { mainComments, chatComments };
  }, [comments]);

  const startChatText = chatComments.length ? 'Show chat' : 'Start chatting about this post';

  const [suggestReplyText, setSuggestReplyText] = useState('');
  const threadFormContainer = useRef<HTMLDivElement | null>(null);

  const applySuggestionText = useCallback(
    (text: string) => {
      setSuggestReplyText(text);
      setTimeout(() => {
        //* Expecting the suggestions to finish their collapse animation before focusing on Input */
        threadFormContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, REPLY_SUGGESTIONS_COLLAPSE_DURATION);
    },
    [setSuggestReplyText],
  );

  const handlePostDelete = async () => {
    await deletePost({ discussionId, threadId: post.TopicThreadId });
    actions.deletePost(post);
    toggleDeleteModal();
  };

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
    setEditText(post.ThreadContent);
    closeControlsPopover();
  };

  return (
    <div
      className={cn(styles.cardWrapper, {
        [styles.moderatorsCardWrapper]: isModerator,
        [styles.rowLayoutWrapper]: rowLayout,
      })}
      ref={postRef}
    >
      <div className={cn(styles.mainContent, { [styles.noChatMargin]: !isModeratorOrObserver })}>
        <div
          className={cn(styles.card, {
            [styles.pinned]: post.IsPinned,
          })}
        >
          <header
            className={cn(styles.header, {
              [styles.headerModerator]: isModerator,
            })}
          >
            <div
              className={cn(styles.userAndControlsWrapper, {
                [styles.answerRequireRibbon]: post.IsResponseMandatory,
              })}
            >
              <div className={styles.userContainer}>
                <div
                  className={cn(styles.avatarContainer, { [styles.avatarWithoutBorder]: activity })}
                >
                  {activity ? (
                    <ActivityIcon activity={activity} peopleSection />
                  ) : (
                    <Avatar
                      alt="Avatar"
                      className={styles.avatar}
                      src={post.ThreadCreatorUserImage}
                    />
                  )}
                </div>

                <div className={styles.userInfo}>
                  <div className={styles.userName}>
                    {activity ? activity.Title : post.ThreadCreatorName}
                  </div>
                  <div className={styles.publishInfo}>
                    {isModerator && (
                      <>
                        <div className={styles.userStatus}>moderator</div>
                        <span className={styles.dividerDot}>•</span>
                      </>
                    )}
                    <time className={styles.published}>
                      {format(new Date(post.CreateDate), 'dd.MM.yy HH:mm')}
                    </time>
                  </div>
                </div>
              </div>
              {(post.CanBeEdited || post.CanBeDeleted) && (
                <div className={styles.headerControls}>
                  {post.IsInToDoList && (
                    <Button
                      className={styles.markAsReadBtn}
                      onClick={() => handleMarkAsRead(post.TopicThreadId)}
                    >
                      Mark as read
                    </Button>
                  )}

                  <Tooltip title={post.IsPinned ? 'Unpin thread' : 'Pin thread'}>
                    <IconButton
                      className={cn(styles.controlsButton, { [styles.pinnedBtn]: post.IsPinned })}
                      onClick={togglePinHandler}
                    >
                      <PushPin
                        className={cn(styles.controlsIcon, { [styles.pinnedIcon]: post.IsPinned })}
                      />
                    </IconButton>
                  </Tooltip>

                  {post.CanBeEdited && (
                    <Tooltip title="Edit">
                      <IconButton className={styles.controlsButton} onClick={toggleEdit}>
                        <Edit className={styles.controlsIcon} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <IconButton className={styles.controlsButton} onClick={openControlsPopover}>
                    <MoreVertSharp className={styles.controlsIcon} />
                  </IconButton>

                  <Popover
                    anchorEl={controlsAnchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    classes={{ paper: 'popover' }}
                    onClose={closeControlsPopover}
                    open={Boolean(controlsAnchorEl)}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    <div className={styles.controlsPopoverContent}>
                      <Button
                        className={styles.controlsPopoverButton}
                        onClick={toggleResponseMandatoryHandler}
                      >
                        <Feedback className={styles.controlsPopoverItemIcon} />
                        <div className={styles.controlsPopoverItemText}>
                          {post.IsResponseMandatory ? 'Response not required' : 'Response required'}
                        </div>
                      </Button>
                      {post.CanBeDeleted && (
                        <Button
                          className={styles.controlsPopoverButton}
                          onClick={toggleDeleteModal}
                        >
                          <DeleteOutline className={styles.controlsPopoverItemIcon} />
                          <div className={styles.controlsPopoverItemText}>Delete</div>
                        </Button>
                      )}
                    </div>
                  </Popover>
                </div>
              )}
            </div>
            <div className={styles.userTagsWrapper}>
              <PostUserTags userId={post.ThreadCreatorId} />
            </div>
          </header>
          <div className={styles.threadContent}>
            <PostContentTags post={post} />

            {edit ? (
              <ThreadForm
                attachments={post.Attachments}
                edit={edit}
                loading={isPostUpdating}
                onCancel={closePostEdit}
                onSubmit={handlePostEdit}
                submitButtonText="Confirm"
                text={editText}
              />
            ) : (
              <div className={styles.content}>
                {summaryText && (
                  <div className={styles.summary}>
                    <div className={styles.summaryHeader}>Summary</div>
                    <div className={styles.summaryText}>{summaryText}</div>
                  </div>
                )}

                {activityType === 6 ? (
                  <FormActivityPost
                    post={post}
                    postHighlights={postHighlights}
                    setPostHighlights={setPostHighlights}
                  />
                ) : (
                  <SocialActivityPost
                    post={post}
                    postHighlights={postHighlights}
                    setPostHighlights={setPostHighlights}
                  />
                )}
              </div>
            )}
            <div className={styles.commentSection}>
              <div className={styles.commentList}>
                {isFetching ? (
                  <Loading className={styles.commentsLoader} />
                ) : (
                  mainComments.map((comment) => (
                    <ActivityComment
                      comment={comment}
                      isAuthor={post.ThreadCreatorId === comment.ThreadCreatorId}
                      key={comment.TopicThreadId}
                      onEdit={handleCommentEdit}
                    />
                  ))
                )}
              </div>
            </div>
            <ReplySuggestions onSelect={applySuggestionText} post={post} />
            <div ref={threadFormContainer}>
              <ThreadForm
                comments={comments}
                loading={isRepliesLoading}
                onCancel={() => setSuggestReplyText('')}
                onSubmit={handleSend}
                post={post}
                text={suggestReplyText}
              />
            </div>
          </div>

          <DeleteModal
            isOpen={showDeleteModal}
            loading={isPostDeleting}
            objectName="post"
            onClose={toggleDeleteModal}
            onConfirm={handlePostDelete}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={NOTIFICATION_DURATION}
            onClose={handleErrorNotificationClose}
            open={showErrorNotification}
          >
            <Alert onClose={handleErrorNotificationClose} severity="error" sx={{ width: '100%' }}>
              Error. Please try again later
            </Alert>
          </Snackbar>
        </div>

        {isModeratorOrObserver && (
          <div className={styles.startChatWrapper}>
            <button className={styles.startChatBtn} onClick={() => setIsChatOpen((prev) => !prev)}>
              {isChatOpen ? 'Hide chat' : startChatText}
              {!isChatOpen && !!chatComments.length && (
                <div className={styles.messagesNumber}>{chatComments.length}</div>
              )}
            </button>
          </div>
        )}

        <div className={styles.taggedChat}>
          {isChatOpen && (
            <div className={styles.commentList}>
              {chatComments.map((comment) => (
                <ActivityComment
                  comment={comment}
                  isAuthor={post.PostedById === comment.PostedById}
                  isChat
                  key={comment.TopicThreadId}
                  onEdit={handleCommentEdit}
                />
              ))}
              <div className={styles.taggedChatForm}>
                <Avatar alt="Avatar" src={loggedUser.ImageURL} sx={{ width: 30, height: 30 }} />
                <ThreadForm
                  comments={comments}
                  isChat
                  loading={isChatRepliesLoading}
                  onSubmit={handleSend}
                  post={post}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isOpenExpandablePanels && (
        <ExpandablePanels
          activityType={activityType}
          highlights={postHighlights}
          post={post}
          setPostHighlights={setPostHighlights}
          skip={!inView}
        />
      )}
    </div>
  );
};
