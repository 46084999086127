import { useMemo } from 'react';
import Emoji from 'react-emoji-render';
import cn from 'classnames';

import { ActivityStats, Question } from '@/types';
import { decodeHtmlSymbols } from '@/utils/decodeHtmlSymbols';
import { purifyHtml } from '@/utils/purifyHtml';

import styles from './questionItem.module.css';

interface Props {
  question: Question;
  activityStats?: ActivityStats;
}

export const QuestionItem: React.FC<Props> = ({ question, activityStats }) => {
  const showQuestion = question.QuestionType !== 21;
  const questionTitle = useMemo(
    () => purifyHtml(decodeHtmlSymbols(question.QuestionTitle), { allowedTags: [] }),
    [question.QuestionTitle],
  );

  const questionStats = useMemo(() => {
    const getChoiceQuestionAnswers = (question: Question) => {
      return activityStats?.ProfilePages[0].ProfileChoiceQuestions.find(
        (choiceQuestion) => choiceQuestion.ProfileQuestionId === question.ProfileQuestionId,
      );
    };
    switch (question.QuestionType) {
      case 16 /** 'Single Selection' question where participants can pick one choice option */:
      case 17: {
        /** 'Multiple Selection' question where participants can pick more than one choice options */
        const answers = getChoiceQuestionAnswers(question);
        if (answers) {
          const answersWithoutHtmlCode = answers.ProfileAnswers.map((answer) => {
            return { ...answer, TextValue: decodeHtmlSymbols(answer.TextValue) };
          });

          const sortedAnswers = answersWithoutHtmlCode.sort((a, b) => {
            if (question.QuestionType === 17 || question.ChoiceOptions.ChoiceRatingType === 0) {
              return b.Value - a.Value;
            }

            if (answers.IsAllNumeric) {
              return +a.TextValue - +b.TextValue;
            }

            return 0;
          });

          const oneAnswerPercentage =
            100 / sortedAnswers.reduce((accum, answer) => accum + answer.Value, 0);

          let averageScore = (
            sortedAnswers.reduce((accum, answer) => accum + +answer.TextValue * answer.Value, 0) /
            answers.RespondentsCount
          ).toFixed(1);

          averageScore = isNaN(+averageScore) ? '0' : averageScore;

          return (
            <>
              {sortedAnswers.map((answer, index, arr) => (
                <div className={styles.ratingField} key={answer.ProfileQuestionChoiceId}>
                  <div className={styles.answerOption}>
                    <Emoji text={purifyHtml(answer.TextValue, { allowedTags: [] })} />
                  </div>
                  <div className={styles.statistics}>
                    <div className={styles.averageScoreWrapper}>
                      <div className={styles.scaleWrapper}>
                        <div className={styles.scale}>
                          {!!answer.Value && (
                            <div
                              className={styles.scaleActive}
                              style={{ width: `${oneAnswerPercentage * answer.Value}%` }}
                            >
                              <div className={cn(styles.rangeDot, styles.rangeDotOnScale)}>
                                {`${Math.round(oneAnswerPercentage * answer.Value)}%`}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {answers.IsAllNumeric && index === arr.length - 1 && (
                        <div className={styles.averageScore}>
                          Average score:
                          <div className={styles.rangeDot}>{averageScore}</div>
                        </div>
                      )}
                    </div>
                    <div className={styles.totalAnswers}>{answer.Value}</div>
                  </div>
                </div>
              ))}
            </>
          );
        }
        return '';
      }

      case 21: {
        // 'Multiple Selection' question where participants can pick more than one choice options but also RANK them in a specific order
        const answers = activityStats?.ProfilePages[0].ProfileRankQuestions.find(
          (choiceQuestion) => choiceQuestion.ProfileQuestionId === question.ProfileQuestionId,
        );

        const answersWithManufacturer = answers?.ProfileAnswers.map((item) => {
          const manufacturer = question.Choices.find(
            (choice) => choice.ChoiceId === item.ProfileQuestionChoiceId,
          )?.ChoiceText;
          return { ...item, manufacturer };
        });

        const sortedAnswers = answersWithManufacturer?.sort((a, b) => a.Average - b.Average);

        const hasRankedLastProperty = sortedAnswers?.some((answer) => answer.TimesSelectedAsLast);

        return (
          <>
            <table>
              <colgroup>
                <col
                  className={cn(styles.manufacturerColumn, {
                    [styles.thinnerManufacturerColumn]: hasRankedLastProperty,
                  })}
                />
                <col className={styles.dataColumn} />
                <col className={styles.dataColumn} />
              </colgroup>
              <thead>
                <tr>
                  <th className={cn(styles.tableHeading, styles.tableName)}>{questionTitle}</th>
                  <th className={styles.tableHeading}>Average Rank</th>
                  <th className={styles.tableHeading}># Ranked First</th>
                  {hasRankedLastProperty && <th className={styles.tableHeading}># Ranked Last</th>}
                </tr>
              </thead>
              <tbody>
                {sortedAnswers?.map((answer, index) => (
                  <tr key={answer.ProfileQuestionChoiceId}>
                    <td className={styles.manufacturer}>
                      <div className={cn(styles.rangeDot, styles.manufacturerDot)}>{index + 1}</div>
                      <div className={styles.manufacturerName}>{answer.manufacturer}</div>
                    </td>
                    <td className={styles.tableCell}>
                      <div className={cn(styles.rangeDot, styles.tableDot)}>
                        {answer.Average.toFixed(1)}
                      </div>
                    </td>
                    <td className={styles.tableCell}>
                      <div className={cn(styles.rangeDot, styles.tableDot)}>
                        {answer.TimesSelectedAsTop}
                      </div>
                    </td>
                    {hasRankedLastProperty && (
                      <td className={styles.tableCell}>
                        <div className={cn(styles.rangeDot, styles.tableDot)}>
                          {answer.TimesSelectedAsLast}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }

      default:
        return 'unknown question type';
    }
  }, [question, questionTitle, activityStats]);

  return (
    <div>
      {showQuestion && <div className={styles.questionTitle}>{questionTitle}</div>}
      <div className={styles.formAnswer}>{questionStats}</div>
    </div>
  );
};
