import { useEffect, useState } from 'react';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { Button, Modal } from '@mui/material';
import cn from 'classnames';

import { useEscapeKey } from '@/hooks';

import styles from './imageCarousel.module.css';

interface Props {
  selectedImageIndex: number | null;
  images: string[];
  onClose: () => void;
}

export const ImageCarousel: React.FC<Props> = ({ images, selectedImageIndex, onClose }) => {
  const [imageIndex, setImageIndex] = useState(-1);
  const [showImageCarousel, setShowImageCarousel] = useState(false);

  useEffect(() => {
    if (selectedImageIndex !== null) {
      setShowImageCarousel(true);
      setImageIndex(selectedImageIndex);
    }
  }, [selectedImageIndex]);

  const handleCloseCarousel = () => {
    setShowImageCarousel(false);
    setImageIndex(-1);
    onClose();
  };

  useEscapeKey(handleCloseCarousel);

  const handlePreviousImage = () => {
    setImageIndex((prev) => {
      if (prev === 0) {
        return images.length - 1;
      }

      return prev - 1;
    });
  };

  const handleNextImage = () => {
    setImageIndex((prev) => {
      if (prev === images.length - 1) {
        return 0;
      }

      return prev + 1;
    });
  };

  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      onClose={handleCloseCarousel}
      open={showImageCarousel}
    >
      <>
        <div className={styles.imageContainer}>
          <img alt="image" className={styles.image} src={images[imageIndex]} />
        </div>
        <div className={styles.imageIndex}>{`${imageIndex + 1}/${images.length}`}</div>

        <Button
          className={cn(styles.carouselButton, styles.closeButton)}
          onClick={handleCloseCarousel}
        >
          <Close />
        </Button>
        <Button
          className={cn(styles.carouselButton, styles.buttonPrevious)}
          onClick={handlePreviousImage}
        >
          <ArrowBack />
        </Button>
        <Button className={cn(styles.carouselButton, styles.buttonNext)} onClick={handleNextImage}>
          <ArrowForward />
        </Button>
      </>
    </Modal>
  );
};
