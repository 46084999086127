import { Drawer } from '@mui/material';

import styles from './activityDrawer.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const ActivityDrawer: React.FC<Props> = ({ open, onClose, children }) => {
  return (
    <Drawer
      anchor="right"
      className={styles.activityDrawer}
      onClose={onClose}
      open={open}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <div className={styles.postsWrapper}>
        <div>{children}</div>
      </div>
    </Drawer>
  );
};
