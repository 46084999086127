import { useState } from 'react';
import cn from 'classnames';

import { useAppSelector, usePosts } from '@/hooks';
import { ActivityCard } from '@/pages/activities/activityPosts/activityCard';
import { Activity } from '@/types';

import styles from './peoplePosts.module.css';

export enum PostTab {
  All = 'All',
  Unread = 'Unread',
}

const postTabs = [PostTab.All, PostTab.Unread] as const;

interface Props {
  activities: Activity[];
  selectedUserId: string;
}

export const PeoplePosts: React.FC<Props> = ({ activities }) => {
  const [activeTab, setActiveTab] = useState(PostTab.All);
  const { pinnedPosts, unpinnedPosts } = usePosts(activeTab);
  const unreadPosts = useAppSelector((state) => state.selectedActivity?.unreadPosts);

  return (
    <div className={styles.peoplePostsWrapper}>
      <div className={styles.postsTabs}>
        {postTabs.map((tab) => (
          <div className={styles.postTabWrapper} key={tab}>
            <button
              className={cn(styles.postsTab, { [styles.postsTabActive]: tab === activeTab })}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
            {tab === PostTab.Unread && !!unreadPosts && (
              <div className={styles.unreadPosts}>{unreadPosts}</div>
            )}
          </div>
        ))}
      </div>
      {pinnedPosts.length || unpinnedPosts.length ? (
        <>
          {!!pinnedPosts.length && (
            <div className={cn(styles.postList, styles.pinnedPosts)}>
              {pinnedPosts.map((post) => (
                <ActivityCard activities={activities} key={post.TopicThreadId} post={post} />
              ))}
            </div>
          )}
          {!!unpinnedPosts.length && (
            <div className={styles.postList}>
              {unpinnedPosts.map((post) => (
                <ActivityCard activities={activities} key={post.TopicThreadId} post={post} />
              ))}
            </div>
          )}
        </>
      ) : (
        <h2 className={styles.noPosts}>No posts</h2>
      )}
    </div>
  );
};
