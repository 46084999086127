import { useMemo } from 'react';

import { userRoles } from '@/constants';
import { useAppSelector } from '@/hooks';

export const useUserRole = () => {
  const userSettings = useAppSelector((state) => state.userSettings);

  const userRole = useMemo(() => {
    if (!userSettings) {
      return null;
    }

    return userRoles[userSettings.TaskMemberRole];
  }, [userSettings]);

  return { userRole };
};
