import { useMemo } from 'react';
import Emoji from 'react-emoji-render';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as ClockIcon } from '@/assets/icons/clock.svg';
import { ReactComponent as PdfFileIcon } from '@/assets/icons/pdf-file.svg';
import { ReactComponent as WebIcon } from '@/assets/icons/web.svg';
import { useAppSelector } from '@/hooks';
import { ActivityPost, Choice, PostHighlight, Question } from '@/types';
import { decodeHtmlSymbols } from '@/utils/decodeHtmlSymbols';

import { HighlightableText } from './highlightableText';

import styles from './formActivityPost.module.css';

interface Props {
  post: ActivityPost;
  question: Question;
  postHighlights: PostHighlight[];
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const SurveyItem: React.FC<Props> = ({
  post,
  question,
  postHighlights,
  setPostHighlights,
}) => {
  const taskMembers = useAppSelector((store) => store.taskMembers);

  const questionTitle = useMemo(() => {
    let questionText = question.QuestionTitle;
    const tagTitles = questionText.match(/##.*##/);
    const tagTitle = tagTitles?.[0] && tagTitles[0].replace(/#/g, '').split('|');
    if (tagTitle) {
      const user = taskMembers.find((user) => user.PersonId === post.ThreadCreatorId);
      const tag = user?.Tags.find((tag) => tag.TagValue.startsWith(tagTitle[0]));
      if (tag) {
        const tagValue = tag.TagValue.split(':')[1];
        questionText = questionText.replace(/##.*##/, tagValue);
      } else {
        questionText = questionText.replace(/##.*##/, tagTitle[1] || '');
      }
    }
    const questionTextWithoutHtml = decodeHtmlSymbols(questionText);

    return <div className={styles.questionTitle}>{questionTextWithoutHtml}</div>;
  }, [question, taskMembers, post.ThreadCreatorId]);

  const answer = useMemo(() => {
    const answerObjects = post.SurveyAnswers.filter(
      (answer) => answer.QuestionId === question.ProfileQuestionId,
    );
    const answerObject = answerObjects.at(0);

    switch (question.QuestionType) {
      case 10:
      case 11: {
        // 10 - 'Short Text' question allowing the participants to enter single-line free text
        // 11 - 'Long Text' question allowing the participants to enter multi-line free text
        const answer = answerObject?.DataTextLong1 || '';
        const answerWithoutLineBreaks = answer.replace(/%0A/g, '');
        return decodeURIComponent(answerWithoutLineBreaks);
      }

      case 16: {
        // 'Single Selection' question where participants can pick one choice option
        const answerType = question.ChoiceOptions.ChoiceRatingType;
        const answerChoice = question.Choices.find(
          (choice) => choice.ChoiceId === answerObject?.DataProfileQuestionChoiceId,
        );

        if (answerType === 1) {
          const answer = answerChoice?.ChoiceValue || 0;
          return (
            <div className={styles.scale}>
              {question.Choices.map((choice) => (
                <div
                  className={cn(styles.scaleDot, {
                    [styles.scaleDotActive]: choice.ChoiceValue === answer,
                  })}
                  key={choice.ChoiceId}
                >
                  <div
                    className={cn(styles.innerDot, {
                      [styles.innerDotActive]: choice.ChoiceValue <= answer,
                    })}
                  >
                    {choice.ChoiceValue === answer ? answer : ''}
                  </div>
                </div>
              ))}
            </div>
          );
        }

        if (answerType === 0) {
          const answer = answerChoice?.ChoiceText || '';
          return (
            <div className={styles.nonRatingScaleAnswer}>
              <Emoji text={answer} />
            </div>
          );
        }

        return <></>;
      }

      case 17: {
        // 'Multiple Selection' question where participants can pick more than one choice options
        const answers = answerObjects.map((answer) =>
          question.Choices.find((choice) => choice.ChoiceId === answer.DataProfileQuestionChoiceId),
        );
        const sortedAnswers = answers
          .filter((item): item is Choice => !!item)
          .sort((a, b) => a.ChoiceValue - b.ChoiceValue);

        return (
          <div className={styles.brands}>
            {sortedAnswers.map((answer) => (
              <div className={styles.brand} key={answer.ChoiceId}>
                {answer.ChoiceText}
              </div>
            ))}
          </div>
        );
      }

      case 18: {
        // 'Time Selection' question where participants can select a range of times
        return (
          <p className={styles.time}>
            <ClockIcon className={styles.clockIcon} />
            {answerObject?.DataTextShort1}
          </p>
        );
      }

      case 19: {
        // 'Image' question where participants can upload one or more images
        const attachmentIds = answerObject?.DataTextLong1.split(';');

        const attachments = attachmentIds?.map((id) =>
          post.Attachments.find((attachment) => attachment.ClientContentId === id),
        );

        const smallImages = attachments?.map((attachment) => {
          return attachment?.Files.find((attachment) => attachment.Type === 2);
        });

        const imageColumns = smallImages && smallImages.length >= 3 ? 3 : 2;

        return (
          <>
            <div className={styles.carImages}>
              {smallImages?.map((image, index) => (
                <div
                  className={styles.carImageWrapper}
                  key={index}
                  style={{ width: `calc(100% / ${imageColumns})` }}
                >
                  <img
                    alt="Car image"
                    className={styles.carImage}
                    key={image?.UserFileId}
                    src={image?.Link}
                  />
                </div>
              ))}
            </div>
          </>
        );
      }

      case 20: {
        //A 'Video' question where participants can upload one or more videos
        const attachmentIds = answerObject?.DataTextLong1.split(';');

        const videos = attachmentIds
          ?.map((attachmentId) =>
            post.Attachments.find((attachment) => attachment.ClientContentId === attachmentId),
          )
          .map((attachment) => attachment?.Files.find((file) => file.Extension === 'mp4'));

        return (
          <>
            {videos?.map((video) => {
              if (video) {
                return (
                  <video controls key={video.UserFileId} src={video.Link} width="100%"></video>
                );
              }
              return;
            })}
          </>
        );
      }

      case 21: {
        // 'Multiple Selection' question where participants can pick more than one choice options but also RANK them in a specific order
        const answers = answerObjects.sort((a, b) => a.Order - b.Order);

        const rankingData = answers.map((answer) => ({
          order: answer.Order + 1,
          brandName: question.Choices.find(
            (choice) => choice.ChoiceId === answer.DataProfileQuestionChoiceId,
          )?.ChoiceText,
        }));

        const data = rankingData.map((data) => (
          <div className={styles.rankItem} key={data.order}>
            <div className={styles.rankNumberWrapper}>
              <div className={styles.rankNumber}>{data.order} </div>
            </div>
            <span className={styles.brandName}>{data.brandName}</span>
          </div>
        ));

        return <div className={styles.ranking}>{data}</div>;
      }
      case 22: {
        // A 'Numeric' question allowing only numbers (with decimals optionally)
        const answer = answerObject?.DataTextShort1.replace(/[{}]+/g, '');

        return <p className={styles.number}>{answer}</p>;
      }

      case 24: {
        // A 'Files' question where participants can upload one or more files of any arbitrary type
        const attachmentIds = answerObject?.DataTextLong1.split(';') || [];
        const attachments = attachmentIds.map((id) =>
          post.Attachments.find((attachment) => attachment.ClientContentId === id),
        );

        return (
          <div className={styles.files}>
            {attachments.map((attachment) => {
              if (attachment) {
                return (
                  <a
                    className={styles.fileLink}
                    href={attachment.Files[0].Link}
                    key={attachment.AttachmentId}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <PdfFileIcon className={styles.fileIcon} />
                    <Tooltip title={attachment.Files[0].OriginalName}>
                      <span className={styles.fileLinkText}>
                        {attachment.Files[0].OriginalName}
                      </span>
                    </Tooltip>
                  </a>
                );
              }
              return;
            })}
          </div>
        );
      }

      case 27: {
        // An audio question where participants can upload an audio file
        const attachmentIds = answerObject?.DataTextLong1.split(';') || [];
        const attachments = attachmentIds.map((id) =>
          post.Attachments.find((attachment) => attachment.ClientContentId === id),
        );

        return (
          <>
            {attachments.map((attachment, index) => (
              <audio controls key={index} src={attachment?.Files[0].Link}></audio>
            ))}
          </>
        );
      }

      case 33: {
        // 'Websites' question that allows the participants to enter web addresses and url's
        const urls = answerObject?.DataTextLong1.split(';') || [];

        return (
          <div className={styles.websites}>
            {urls.map((url, index) => (
              <div className={styles.website} key={index}>
                <WebIcon className={styles.webIcon} />
                <a className={styles.websiteLink} href={url} rel="noreferrer" target="_blank">
                  {url}
                </a>
              </div>
            ))}
          </div>
        );
      }

      default:
        return <div>unknown question type</div>;
    }
  }, [question, post]);

  return (
    <>
      {questionTitle}
      <div className={styles.formAnswer}>
        {[10, 11].includes(question.QuestionType) ? (
          <HighlightableText
            highlights={postHighlights.filter(
              (highlight) => highlight.QuestionId === question.ProfileQuestionId,
            )}
            postId={post.TopicThreadId}
            questionId={question.ProfileQuestionId}
            setHighlights={setPostHighlights}
            textContent={answer as string}
          />
        ) : (
          answer
        )}
      </div>
    </>
  );
};
