import styles from './statisticsTab.module.css';

export const StatisticsTab: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Activity One</div>
      <div className={styles.content}>
        <h2>How likely are you to get a fully-electric car for your next car?</h2>
      </div>
      <div className={styles.header}>Activity Two</div>
      <div className={styles.content}>
        <h2>How likely are you to get a fully-electric car for your next car?</h2>
      </div>
    </div>
  );
};
