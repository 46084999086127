import { useMemo } from 'react';

import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery } from '@/store/api';
import { ActivityPost, PostHighlight } from '@/types';

import { SurveyItem } from './surveyItem';

import styles from './formActivityPost.module.css';

interface Props {
  post: ActivityPost;
  postHighlights: PostHighlight[];
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
}

export const FormActivityPost: React.FC<Props> = ({ post, postHighlights, setPostHighlights }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: activityDetails } = useGetActivityDetailsQuery({
    discussionId,
    activityId: post.DiscussionTopicId,
  });

  const questionsList = useMemo(() => {
    const questions = activityDetails?.at(0)?.SurveyOptions?.Survey?.Pages?.at(0)?.Questions || [];

    return questions.filter((question) =>
      post.SurveyAnswers.some((answer) => answer.QuestionId === question.ProfileQuestionId),
    );
  }, [post, activityDetails]);

  return (
    <div className={styles.survey}>
      {questionsList.map((question) => (
        <SurveyItem
          key={question.ProfileQuestionId}
          post={post}
          postHighlights={postHighlights}
          question={question}
          setPostHighlights={setPostHighlights}
        />
      ))}
    </div>
  );
};
