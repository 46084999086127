import styles from '@/components/topSection/selectSectionModal.module.css';
import { activitySectionIconsMap } from '@/constants/activitySectionIconsMap';
import { ActivitySection } from '@/types';

interface Props {
  isDisabled: boolean;
  section: ActivitySection;
  onClick: (section: ActivitySection) => void;
}

export const SelectSectionButton: React.FC<Props> = ({ isDisabled, section, onClick }) => {
  return (
    <button
      className={styles.selectSectionBtn}
      disabled={isDisabled}
      onClick={() => {
        onClick(section);
      }}
    >
      <div className={styles.iconWrapper}>{activitySectionIconsMap[section]}</div>
      <div className={styles.title}>{section}</div>
    </button>
  );
};
