import { format } from 'date-fns';

import styles from './creatorBadge.module.css';

interface Props {
  avatar: string;
  date: string;
  name: string;
}

export const CreatorBadge: React.FC<Props> = ({ avatar, date, name }) => {
  return (
    <div className={styles.postCreatorBadge}>
      <img alt="post creator avatar" className={styles.userAvatar} src={avatar} />
      <div className={styles.userInfoWrapper}>
        <div className={styles.userName}>{name}</div>
        <div className={styles.date}>{format(date, 'dd.MM.yy HH:mm')}</div>
      </div>
    </div>
  );
};
