import { useState } from 'react';
import { Button } from '@mui/material';
import cn from 'classnames';

import { NumberInCircle } from '@/components/numberInCircle';
import styles from '@/pages/people/peopleList/filterUsersDropdown.module.css';
import { MemberCount } from '@/types';

interface Props {
  memberCount: MemberCount;
  onSelect: (memberCount: MemberCount | null) => void;
}

export const FilterButton: React.FC<Props> = ({ memberCount, onSelect }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Button
      className={cn(styles.filterButton, { [styles.filterButtonDisabled]: !memberCount.Count })}
      onClick={() => onSelect(memberCount)}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      {memberCount.Id}
      {!!memberCount.Count && (
        <NumberInCircle
          backgroundColor={'#eff0f5'}
          backgroundColorHover={'#258e61'}
          borderColor={'#eff0f5'}
          borderColorHover={'#fff'}
          color={'#000'}
          colorHover={'#fff'}
          isHovering={isHovering}
          number={memberCount.Count}
        />
      )}
    </Button>
  );
};
