import { RefObject } from 'react';

import { useAppSelector, useClickOutside, useEscapeKey } from '@/hooks';
import { Color } from '@/types';

import { ColorButton } from './colorButton';

import styles from './highlightingColorModal.module.css';

interface Props {
  suggestedHighlightColor?: Color | null;
  isOpen: boolean;
  onClose: () => void;
  onColorSelect: (color: Color) => void;
  highlightModalBtnRef: RefObject<HTMLElement>;
}

export const HighlightingColorModal: React.FC<Props> = ({
  suggestedHighlightColor,
  isOpen,
  onClose,
  onColorSelect,
  highlightModalBtnRef,
}) => {
  const colorsMap = useAppSelector((state) => state.topSection.colorsMap);
  const selectedColor =
    suggestedHighlightColor !== undefined
      ? suggestedHighlightColor
      : useAppSelector((state) => state.topSection.highlightColor);

  useEscapeKey(onClose);

  const handleClickOutside = (event: MouseEvent) => {
    if (highlightModalBtnRef.current?.contains(event.target as Node)) {
      return;
    }
    onClose();
  };

  const modalRef = useClickOutside(handleClickOutside);

  return (
    <div className={styles.modalWrapper} ref={modalRef}>
      {isOpen && (
        <div className={styles.mainModal}>
          <div className={styles.colors}>
            {colorsMap.map((color) => (
              <ColorButton
                active={color.HighlightColorId === selectedColor?.HighlightColorId}
                color={color}
                key={color.HighlightColorId}
                onClick={onColorSelect}
                showTitle
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
