import cn from 'classnames';

import { StatisticsItem } from '@/components/header/statisticsItem';
import { useAppSelector } from '@/hooks';
import { useGetStatsOverviewQuery } from '@/store/api';

import styles from './statistics.module.css';

interface Props {
  layoutVariant?: number;
}

export const Statistics: React.FC<Props> = ({ layoutVariant = 0 }) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);

  const { data } = useGetStatsOverviewQuery(discussionId, {
    skip: !discussionId,
  });

  if (!data) {
    return null;
  }

  const { Invited, Participants, Started, UpToDate } = data;

  return (
    <div className={cn(styles.statistics, styles[`layout${layoutVariant}`])}>
      <StatisticsItem
        color="Green"
        difference={Invited - Participants}
        layoutVariant={layoutVariant}
        number={Invited}
        title="Invited"
      />
      <StatisticsItem
        color="Orange"
        difference={Participants - Started}
        layoutVariant={layoutVariant}
        number={Participants}
        title="Joined"
      />
      <StatisticsItem
        color="Blue"
        difference={Started - UpToDate}
        layoutVariant={layoutVariant}
        number={Started}
        title="Started"
      />
      <StatisticsItem
        color="Purple"
        layoutVariant={layoutVariant}
        number={UpToDate}
        title="Up-to-Date"
      />
    </div>
  );
};
