import type { ActivityPost, PostRequestBody, ReplySuggestion } from '@/types';

import { api } from '.';

interface RepliesArgsInput {
  discussionId: string;
  threadId: string;
}

interface ReplyArgsInput extends RepliesArgsInput {
  body: PostRequestBody;
  activityId: string;
}

export const {
  useGetPostRepliesQuery,
  useSendReplyMutation,
  useEditReplyMutation,
  useDeleteReplyMutation,
  useGetSuggestionRepliesQuery,
  useGenerateSuggestionRepliesMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getPostReplies: builder.query<ActivityPost[], RepliesArgsInput>({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/replies`,
        method: 'GET',
      }),
    }),

    sendReply: builder.mutation<ActivityPost, ReplyArgsInput>({
      query: ({ discussionId, activityId, threadId, body }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}/posts/${threadId}/replies`,
        body: body,
        method: 'POST',
      }),
    }),

    editReply: builder.mutation<ActivityPost, ReplyArgsInput>({
      query: ({ discussionId, activityId, threadId, body }) => ({
        url: `v3/discussions/${discussionId}/activities/${activityId}/posts/${threadId}/replies`,
        body: { ...body, ThreadId: threadId },
        method: 'PUT',
      }),
      invalidatesTags: (_, error) => (error ? ['Posts'] : []),
    }),

    deleteReply: builder.mutation({
      query: ({ discussionId, postId }) => ({
        url: `v3/discussions/${discussionId}/threads/${postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? ['Posts'] : []),
    }),

    getSuggestionReplies: builder.query<
      ReplySuggestion[],
      { discussionId: string; threadId: string }
    >({
      query: ({ discussionId, threadId }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/aimodresponses`,
      }),
    }),

    generateSuggestionReplies: builder.mutation<
      ReplySuggestion[],
      { discussionId: string; threadId: string; prompt?: string }
    >({
      query: ({ discussionId, threadId, prompt }) => ({
        url: `v3/discussions/${discussionId}/threads/${threadId}/aimodresponses`,
        method: 'PUT',
        body: { prompt },
      }),
    }),
  }),
});
