import { useMemo } from 'react';
import TextBalancer from 'react-wrap-balancer';

import { ReactComponent as AddTagsIcon } from '@/assets/icons/addTags.svg';
import { ReactComponent as EyeCrossed } from '@/assets/icons/eyeCrossed.svg';
import { ReactComponent as Person } from '@/assets/icons/person.svg';
import { Tag } from '@/types';

import styles from './tagsModal.module.css';

interface Props {
  tag: Tag;
  checked: boolean;
  onToggle: (tag: Tag, checked: boolean) => void;
}

export const TagRowItem: React.FC<Props> = ({ tag, checked, onToggle }) => {
  const tagName = useMemo(() => {
    const name = tag.TagValue.split(':')
      .map((item) => item[0].toUpperCase() + item.slice(1).toLowerCase())
      .join(':');

    if (name.length > 25) {
      return `${name.slice(0, 10)}...${name.slice(-10)}`;
    }

    return name;
  }, [tag.TagValue]);

  const tagIcon = useMemo(() => {
    return (
      {
        0: <EyeCrossed style={{ color: tag.TagColor }} />,
        2: <Person style={{ color: tag.TagColor }} />,
      }[tag.Visibility] || null
    );
  }, [tag.Visibility, tag.TagColor]);

  const handleChange = () => {
    onToggle(tag, !checked);
  };

  return (
    <label className={styles.tagLabel}>
      <div className={styles.tagNameWrapper}>
        <AddTagsIcon style={{ color: tag.TagColor }} />
        <span className={styles.tagName} title={tag.TagValue}>
          <TextBalancer>{tagName}</TextBalancer>
          {tagIcon}
        </span>
      </div>
      <input
        checked={checked}
        className={styles.checkbox}
        onChange={handleChange}
        type="checkbox"
      />
      <div className={styles.customCheckbox}></div>
    </label>
  );
};
