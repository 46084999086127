import { Theme } from '@/types';

import { api } from '.';

export const { useGetThemesQuery } = api.injectEndpoints({
  endpoints: (builder) => ({
    getThemes: builder.query<Theme[], string>({
      query: (discussionId) => ({
        url: `v3/discussions/${discussionId}/themes`,
        method: 'GET',
      }),
    }),
  }),
});
