import { useMemo } from 'react';

import { useActions, useAppSelector } from '@/hooks';

import { Tags } from './tags';

interface Props {
  userId: string;
}

export const PostUserTags: React.FC<Props> = ({ userId }) => {
  const actions = useActions();
  const taskMembers = useAppSelector((state) => state.taskMembers);

  const { userTags, taskMemberId } = useMemo(() => {
    const user = taskMembers.find((user) => user.PersonId === userId);
    return {
      userTags: user?.Tags || [],
      taskMemberId: user?.TaskMemberId || '',
    };
  }, [taskMembers, userId]);

  return (
    <Tags
      apiId={taskMemberId}
      context="participants"
      onChange={(tags) => {
        actions.updateTaskMemberTags({ taskMemberId, tags });
      }}
      tags={userTags}
    />
  );
};
