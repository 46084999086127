import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

import { ReactComponent as WidgetsIcon } from '@/assets/icons/widgets.svg';
import { useAppSelector } from '@/hooks';
import { useCreateNewHighlightMutation } from '@/store/api/highlights';
import { useGetThemesQuery } from '@/store/api/themes';
import { PostHighlight, PostHighlightInputBody, Theme } from '@/types';

import { ThemesModal } from './themesModal';

import styles from './themeBadge.module.css';

interface Props {
  highlight?: PostHighlight;
  setPostHighlights?: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
  selectedThemes: Theme[];
  setSelectedThemes: React.Dispatch<React.SetStateAction<Theme[]>>;
}

export const ThemeBadge: React.FC<Props> = ({
  highlight,
  setPostHighlights,
  selectedThemes,
  setSelectedThemes,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const { data: themes } = useGetThemesQuery(discussionId);
  const [editHighlight] = useCreateNewHighlightMutation();

  const badgeName = useMemo(() => {
    if (!selectedThemes.length) {
      return 'No theme selected';
    } else if (selectedThemes.length === 1) {
      return selectedThemes[0].Name;
    } else {
      return selectedThemes.map((theme) => theme.Name).join(' | ');
    }
  }, [selectedThemes]);

  const handleSelectTheme = async (theme: Theme) => {
    const themeAlreadySelected = selectedThemes.some(
      (selectedTheme) => selectedTheme.DiscussionThemeId === theme.DiscussionThemeId,
    );
    if (themeAlreadySelected) {
      setSelectedThemes((prev) =>
        prev.filter((selectedTheme) => selectedTheme.DiscussionThemeId !== theme.DiscussionThemeId),
      );
    } else {
      setSelectedThemes((prev) => [...prev, theme]);
    }

    if (highlight && setPostHighlights) {
      const { Start, End, Text, ThreadId, HighlightId, Note, TextForReport, Color, Tags } =
        highlight;

      const body: PostHighlightInputBody = {
        HighlightId,
        Start,
        End,
        ThreadId,
        Text,
        'Color[HighlightColorId]': Color.HighlightColorId,
        ...(Note && { Note }),
        ...(TextForReport && { TextForReport }),
      };

      Tags.forEach((tag, index) => (body[`Tags[${index}][ContentTagId]`] = tag.ContentTagId));

      let themes: Theme[];

      if (themeAlreadySelected) {
        themes = selectedThemes.filter(
          ({ DiscussionThemeId }) => DiscussionThemeId !== theme.DiscussionThemeId,
        );
      } else {
        themes = [...selectedThemes, theme];
      }
      themes.forEach(
        (theme, index) =>
          (body[`DiscussionThemes[${index}][DiscussionThemeId]`] = theme.DiscussionThemeId),
      );

      const response = await editHighlight({ discussionId, threadId: ThreadId, body });

      if ('error' in response) {
        toast.error('Error: Unable to edit highlight. Please try again later.');
        return;
      } else {
        const newHighlight = response.data;
        setPostHighlights((prevHighlights) => {
          const filteredHighlights = prevHighlights.filter(
            (highlight) => highlight.HighlightId !== newHighlight.HighlightId,
          );
          return [...filteredHighlights, newHighlight];
        });
      }
    }
  };

  return (
    <div className={styles.themesWrapper}>
      <div className={cn(styles.themeBadge, { [styles.activeBadge]: !!anchorEl })}>
        <IconButton
          className={styles.themeIconContainer}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <WidgetsIcon />
        </IconButton>
        {badgeName}
      </div>
      {themes && (
        <ThemesModal
          highlightThemes={selectedThemes}
          onSelect={handleSelectTheme}
          open={anchorEl}
          setAnchorEl={setAnchorEl}
          themes={themes}
        />
      )}
    </div>
  );
};
