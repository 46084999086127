import { useEffect, useMemo, useState } from 'react';

import { Loading } from '@/components/loading';
import { NavigationDrawer } from '@/components/navigation/navigationDrawer';
import { TopSection } from '@/components/topSection';
import { useActions, useAppSelector, useWindowDimensions } from '@/hooks';
import { HighlightsSection } from '@/pages/activities/highlightsSection';
import { ImagesSection } from '@/pages/activities/imagesSection';
import { VideosSection } from '@/pages/activities/videosSection';
import { useGetActivitiesQuery, useGetTaskMembersQuery } from '@/store/api';
import { useGetUserPostsQuery } from '@/store/api/people';
import { ActivitySection, MemberCount, User } from '@/types';

import { PeopleList } from './peopleList';
import { PeoplePosts } from './peoplePosts';

import styles from './people.module.css';

export const People = () => {
  const actions = useActions();
  const [users, setUsers] = useState<User[]>([]);
  const [memberCount, setMemberCount] = useState<MemberCount | null>(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const { width: windowWidth } = useWindowDimensions();
  const isDrawerOpen = useAppSelector((state) => state.drawer.isOpen) && windowWidth < 1440;
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activeSection = useAppSelector((state) => state.topSection.activeSection);

  const { data: usersList, isFetching: isUsersFetching } = useGetTaskMembersQuery(
    { discussionId, role: memberCount ? memberCount.Role.toString() : '' },
    {
      skip: !discussionId,
    },
  );

  const { data: posts, isFetching } = useGetUserPostsQuery(
    { discussionId, personId: selectedUserId },
    {
      skip: !discussionId || !selectedUserId,
    },
  );

  const { data: activities = [] } = useGetActivitiesQuery(discussionId, {
    skip: !discussionId,
  });

  useEffect(() => {
    if (!usersList) {
      return;
    }
    const { PersonId, DisplayName } = usersList[0];
    setUsers(usersList);
    setSelectedUserId(PersonId);
    actions.setTaskMembers(usersList);
    actions.setSelectedUser({ id: PersonId, name: DisplayName });
  }, [usersList, actions]);

  useEffect(() => {
    if (posts) {
      actions.setPosts(posts);
    }
  }, [posts, actions]);

  const handleSelectedUser = (user: User) => {
    const { PersonId, DisplayName } = user;
    setSelectedUserId(PersonId);
    actions.setSelectedUser({ id: PersonId, name: DisplayName });
    actions.closeDrawer();
  };

  const handleToggleDrawer = () => {
    actions.toggleDrawer();
  };

  const handleUsersFiltering = (memberCount: MemberCount | null): void => {
    if (memberCount === null) {
      setMemberCount(null);
    } else {
      setMemberCount(memberCount);
    }
  };

  const activeSectionContent = useMemo(() => {
    return {
      [ActivitySection.Posts]: (
        <PeoplePosts activities={activities} selectedUserId={selectedUserId} />
      ),
      [ActivitySection.Highlights]: <HighlightsSection isPeople />,
      [ActivitySection.Images]: <ImagesSection />,
      [ActivitySection.Videos]: <VideosSection />,
    }[activeSection];
  }, [activeSection, activities, selectedUserId]);

  return (
    <div>
      <TopSection title="People" />
      <NavigationDrawer onClose={handleToggleDrawer} open={isDrawerOpen}>
        <div className={styles.drawerList}>
          <h2 className={styles.drawerTitle}>People</h2>
          <PeopleList
            isLoading={isUsersFetching}
            memberCount={memberCount}
            onUserSelect={handleSelectedUser}
            onUsersFilter={handleUsersFiltering}
            selectedUserId={selectedUserId}
            users={users}
          />
        </div>
      </NavigationDrawer>
      <div className={styles.container}>
        <div className={styles.listContainer}>
          <PeopleList
            isLoading={isUsersFetching}
            memberCount={memberCount}
            onUserSelect={handleSelectedUser}
            onUsersFilter={handleUsersFiltering}
            selectedUserId={selectedUserId}
            users={users}
          />
        </div>
        {isFetching ? (
          <Loading />
        ) : (
          <div className={styles.contentWrapper}>{activeSectionContent}</div>
        )}
      </div>
    </div>
  );
};
