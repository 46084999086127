import { useMemo } from 'react';

import { User } from '@/types';

const formatTime = (time: string, withoutMins = false): string => {
  const [hours, minutes] = time.split(':');

  if (withoutMins) {
    return `${+hours} h`;
  }

  if (+hours) {
    return `${+hours} h ${minutes} m`;
  }

  return `${+minutes} m`;
};
const formatDate = (time: string): string => {
  const dateArr = time.split('.');

  switch (dateArr.length) {
    case 1: {
      return formatTime(dateArr[0]);
    }

    case 2: {
      if (dateArr[0].includes(':')) {
        return formatTime(dateArr[0]);
      }
      const days = dateArr[0];
      const time = formatTime(dateArr[1]);
      if (+days) {
        return `${days} d ${formatTime(dateArr[1], true)}`;
      }
      return time;
    }

    case 3: {
      const days = dateArr[0];
      const time = formatTime(dateArr[1]);
      if (+days) {
        return `${days} d ${formatTime(dateArr[1], true)}`;
      }
      return time;
    }
  }
  return '';
};

export const useUserData = (user: User) => {
  const userData = useMemo(() => {
    const formTooltipString = (engagementTime: string, lastPostTime: string): string => {
      if (engagementTime && lastPostTime) {
        return `Enaged for ${engagementTime}. Last post ${lastPostTime}.`;
      }

      return '';
    };

    const completeCount = user.TopicCompletionData.reduce((accum, item) => {
      return item.Status === 1 ? accum + 1 : accum;
    }, 0);

    const notCompleteCount = user.TopicCompletionData.reduce((accum, item) => {
      return item.Status === 2 ? accum + 1 : accum;
    }, 0);

    const engagementTime = user.EngagementTime ? formatDate(user.EngagementTime) : '';
    const lastPostTime = user.LastPost ? `${formatDate(user.LastPost)} ago` : '';

    const timeTooltip = formTooltipString(engagementTime, lastPostTime);

    return { completeCount, notCompleteCount, engagementTime, lastPostTime, timeTooltip };
  }, [user]);

  return userData;
};
