import { RefObject } from 'react';

import { useClickOutside, useEscapeKey } from '@/hooks';

interface Props {
  className: string;
  children: React.ReactNode;
  onClose: () => void;
  outsideTriggerBtnRef: RefObject<HTMLElement>;
}

export const PopupWrapper: React.FC<Props> = ({
  outsideTriggerBtnRef,
  children,
  onClose,
  className,
}) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (outsideTriggerBtnRef.current?.contains(event.target as Node)) {
      return;
    }
    onClose();
  };

  const popupRef = useClickOutside(handleClickOutside);

  useEscapeKey(onClose);
  return (
    <div className={className} ref={popupRef}>
      {children}
    </div>
  );
};
