import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CookiesConsent } from '@/components/cookiesConsent';
import { Header } from '@/components/header';
import { MainPreloader } from '@/components/mainPreloader';
import { Navigation } from '@/components/navigation/navigation';
import { useActions } from '@/hooks';
import { useGetDiscussionsQuery, useGetUserInfoQuery, useGetUserSettingsQuery } from '@/store/api';
import { session } from '@/utils/session';

import styles from './layout.module.css';

export const Layout = () => {
  const actions = useActions();
  const [isLoading, setIsLoading] = useState(true);
  const userId = session.accessId.get();
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetUserSettingsQuery(userId);
  const discussionId = userSettings?.DiscussionId || '';

  useEffect(() => {
    if (userSettings) {
      session.personToken.set(userSettings.PersonToken);
      actions.setUserSettings(userSettings);
    }
  }, [userSettings, actions]);

  const { data: userInfo, isLoading: isUserInfoLoading } = useGetUserInfoQuery(discussionId, {
    skip: !discussionId,
  });

  useEffect(() => {
    if (userInfo?.length) {
      actions.setUser(userInfo[0]);
    }
  }, [userInfo, actions]);

  const { data: discussions, isLoading: isDiscussionsLoading } = useGetDiscussionsQuery(
    discussionId,
    { skip: !discussionId },
  );

  useEffect(() => {
    if (discussions) {
      actions.setDiscussions(discussions);
    }
  }, [discussions, actions]);

  const loading = isLoading || isUserSettingsLoading || isUserInfoLoading || isDiscussionsLoading;

  return (
    <>
      {loading && <MainPreloader />}
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <Header onLoad={() => setIsLoading(false)} />
          <div className={styles.innerWrapper}>
            <Navigation className={styles.navigation} />
            <div className={styles.content}>
              <Outlet />
              <CookiesConsent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
