import { SelectSectionButton } from '@/components/topSection/selectSectionButton';
import { useClickOutside, useEscapeKey } from '@/hooks';
import { ActivitySection, ActivityTabContentAvailability } from '@/types';

import styles from './selectSectionModal.module.css';

interface Props {
  activityTabContentAvailability: ActivityTabContentAvailability;
  isOpen: boolean;
  onClose: () => void;
  sections: ActivitySection[];
  onSectionChange: (section: ActivitySection) => void;
  selectSectionBtnRef: React.RefObject<HTMLButtonElement>;
}

export const SelectSectionModal: React.FC<Props> = ({
  activityTabContentAvailability,
  isOpen,
  onClose,
  onSectionChange,
  sections,
  selectSectionBtnRef,
}) => {
  useEscapeKey(onClose);

  const handleClickOutside = (event: MouseEvent) => {
    if (selectSectionBtnRef.current?.contains(event.target as Node)) {
      return;
    }
    onClose();
  };

  const modalRef = useClickOutside(handleClickOutside);

  return (
    <>
      {isOpen && (
        <div className={styles.selectSectionModal} ref={modalRef}>
          {sections.map((section) => (
            <SelectSectionButton
              isDisabled={!activityTabContentAvailability[section]}
              key={section}
              onClick={onSectionChange}
              section={section}
            />
          ))}
        </div>
      )}
    </>
  );
};
