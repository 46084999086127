import { useEffect, useMemo, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import cn from 'classnames';

import { Loading } from '@/components/loading';
import { REPLY_SUGGESTIONS_COLLAPSE_DURATION } from '@/constants';
import { useAppSelector } from '@/hooks';
import {
  useGenerateSuggestionRepliesMutation,
  useGetSuggestionRepliesQuery,
} from '@/store/api/replies';
import { ActivityPost, ReplySuggestion } from '@/types';
import { trimSurroundingDoubleQuotes } from '@/utils/trimSurroundingDoubleQuotes';

import { ReplyPromptModal } from './replyPromptModal';
import { Suggestions } from './suggestions';

import styles from './replySuggestions.module.css';

enum Tab {
  Generate = 'generate',
  Suggest = 'suggest',
}

const tabs = [
  { label: 'Generate a new reply', value: Tab.Generate },
  { label: 'Suggest replies', value: Tab.Suggest },
];

interface Props {
  post: ActivityPost;
  onSelect: (suggestionText: string) => void;
}

export const ReplySuggestions: React.FC<Props> = ({ post, onSelect }) => {
  const threadId = post.TopicThreadId;
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [activeTab, setActiveTab] = useState<Tab | null>(null);
  const [showReplyPromptModal, setShowReplyPromptModal] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [replySuggestions, setReplySuggestions] = useState(post.ReplySuggestions || []);
  const [generatedReplySuggestions, setGeneratedReplySuggestions] = useState<ReplySuggestion[]>([]);

  const [generateSuggestionReplies, { isError, isLoading, isUninitialized }] =
    useGenerateSuggestionRepliesMutation();

  const { data: loadedReplySuggestions, isFetching } = useGetSuggestionRepliesQuery(
    { threadId, discussionId },
    {
      skip:
        activeTab !== Tab.Suggest || !!post.ReplySuggestions?.length || !discussionId || !threadId,
    },
  );

  useEffect(() => {
    if (!loadedReplySuggestions) {
      return;
    }
    setReplySuggestions(loadedReplySuggestions);
  }, [loadedReplySuggestions]);

  const collapse = () => {
    setActiveTab(null);
  };

  const onSuggestionClick = (suggestion: ReplySuggestion) => {
    onSelect(suggestion.SuggestionText);
    collapse();
  };

  const generateNewSuggestions = async (prompt: string) => {
    setShowReplyPromptModal(false);
    setPrompt(prompt);
    const res = await generateSuggestionReplies({ threadId, discussionId, prompt });
    if ('data' in res) {
      setGeneratedReplySuggestions(res.data);
      setReplySuggestions(res.data);
    }
  };

  const tabClickHandler = (tab: Tab) => {
    setActiveTab(activeTab === tab ? null : tab);

    if (activeTab !== Tab.Generate && tab === Tab.Generate && isUninitialized) {
      setShowReplyPromptModal(true);
    }

    setReplySuggestions(
      tab === Tab.Generate
        ? generatedReplySuggestions
        : loadedReplySuggestions || post.ReplySuggestions || [],
    );
  };

  const normalizedSuggestions = useMemo(
    () =>
      replySuggestions.map((suggestion) => ({
        ...suggestion,
        SuggestionText: trimSurroundingDoubleQuotes(suggestion.SuggestionText.trim()),
      })),
    [replySuggestions],
  );

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {tabs.map(({ value, label }) => (
          <Button
            className={cn(styles.tab, { [styles.tabActive]: activeTab === value })}
            disabled={isFetching || isLoading}
            disableRipple
            key={value}
            onClick={() => tabClickHandler(value)}
            variant="text"
          >
            {label}
          </Button>
        ))}
        {showReplyPromptModal && (
          <ReplyPromptModal
            closeModal={() => {
              setShowReplyPromptModal(false);
              if (isUninitialized) {
                collapse();
              }
            }}
            onSubmit={generateNewSuggestions}
          />
        )}
      </div>
      {isFetching || isLoading ? (
        <Loading className={styles.loader} />
      ) : isError ? (
        <p className={styles.errorMessage}>Ooops, request failed</p>
      ) : (
        <Collapse in={!!activeTab} timeout={REPLY_SUGGESTIONS_COLLAPSE_DURATION}>
          <Suggestions
            onClose={collapse}
            onRegenerate={() => setShowReplyPromptModal(true)}
            onSelect={onSuggestionClick}
            prompt={activeTab === Tab.Generate ? prompt : undefined}
            suggestions={normalizedSuggestions}
          />
        </Collapse>
      )}
    </div>
  );
};
