import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, IconButton, Modal } from '@mui/material';

import { ThreadForm } from '@/components/threadForm';
import { useActions, useAppSelector, useUserRole } from '@/hooks';
import { useSendActivityPostMutation } from '@/store/api';
import { ThreadData } from '@/types';

import styles from './topSection.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NewPostModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const actions = useActions();
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const activityId = useAppSelector((state) => state.selectedActivity.id);
  const currentUser = useAppSelector((state) => state.user);

  const { userRole } = useUserRole();

  const [createPost, { isError, isLoading }] = useSendActivityPostMutation();

  const handleSubmit = async ({ text, attachments }: ThreadData) => {
    const body = { ThreadContent: text.trim(), Attachments: attachments, Visibility: 0 };
    const response = await createPost({ discussionId, activityId, body });

    if ('data' in response) {
      actions.addPost(response.data);
      onClose();
    } else {
      console.error(response.error);
    }
  };

  return (
    <Modal
      aria-describedby="Modal window for adding new posts"
      aria-labelledby="Add new post"
      onClose={onClose}
      open={isOpen}
    >
      <div className={styles.postModalWrapper}>
        <Box className={styles.postModal}>
          <div className={styles.postAuthor}>
            <Avatar alt="Avatar" className={styles.avatar} src={currentUser.ImageURL} />
            <div className={styles.authorInfo}>
              <h3 className={styles.authorName}>{currentUser.FullName}</h3>
              <span className={styles.authorStatus}>you ({userRole})</span>
            </div>
            <div className={styles.closeModal}>
              <IconButton className={styles.closeModalButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <ThreadForm addNewPost={isOpen} loading={isLoading} onSubmit={handleSubmit} />
          {isError && <div className={styles.errorMessage}>Ooops, request failed</div>}
        </Box>
      </div>
    </Modal>
  );
};
