import { useEffect, useState } from 'react';

import { Loading } from '@/components/loading';
import { useAppSelector } from '@/hooks';
import { useGetActivityDetailsQuery } from '@/store/api';
import { useGetSuggestedHighlightsQuery } from '@/store/api/highlights';
import { ActivityPost, PostHighlight, SuggestedHighlight } from '@/types';

import { Highlights } from './highlights';
import { Notes } from './notes';
import { SuggestedHighlights } from './suggestedHighlights';

import styles from '../activityCard.module.css';

interface Props {
  activityType: number;
  post: ActivityPost;
  highlights: PostHighlight[];
  setPostHighlights: React.Dispatch<React.SetStateAction<PostHighlight[]>>;
  skip: boolean;
}

export const ExpandablePanels: React.FC<Props> = ({
  activityType,
  post,
  highlights = [],
  setPostHighlights,
  skip,
}) => {
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const [suggestedHighlights, setSuggestedHighlights] = useState<SuggestedHighlight[]>([]);
  const { data: activityDetails } = useGetActivityDetailsQuery({
    discussionId,
    activityId: post.DiscussionTopicId,
  });

  const { data, isLoading } = useGetSuggestedHighlightsQuery(
    { discussionId, threadId: post.TopicThreadId },
    { skip },
  );

  useEffect(() => {
    if (data) {
      let suggestedHighlights;

      if (activityType === 6) {
        const questions =
          activityDetails?.at(0)?.SurveyOptions?.Survey?.Pages?.at(0)?.Questions || [];
        const textQuestions = questions.filter(({ QuestionType }) =>
          [10, 11].includes(QuestionType),
        );

        const filteredTextQuestions = textQuestions.filter((question) =>
          post.SurveyAnswers.some((answer) => answer.QuestionId === question.ProfileQuestionId),
        );

        const filteredAnswers = post.SurveyAnswers.filter((answer) =>
          filteredTextQuestions.some(
            (question) => question.ProfileQuestionId === answer.QuestionId,
          ),
        );

        const answersWithDecodedText = filteredAnswers.map((answer) => ({
          ...answer,
          DataTextLong1: decodeURIComponent(answer.DataTextLong1.replace(/%0A/g, '')),
        }));

        const highlightsWithQuestionId: SuggestedHighlight[] = [];

        answersWithDecodedText.forEach((answer) => {
          data.SuggestedHighlights.forEach((highlight) => {
            const answerContainsHighlightText = highlight.Sentences.every((sentence) =>
              answer.DataTextLong1.includes(sentence.Text),
            );
            if (answerContainsHighlightText) {
              highlightsWithQuestionId.push({
                ...highlight,
                QuestionId: answer.QuestionId,
              });
            }
          });
        });
        suggestedHighlights = highlightsWithQuestionId.map((highlight) => {
          const text =
            answersWithDecodedText.find((answer) => answer.QuestionId === highlight.QuestionId)
              ?.DataTextLong1 || '';
          return getHighlightIndexes(highlight, text);
        });
      } else {
        suggestedHighlights = data.SuggestedHighlights.map((highlight) => {
          const text = post.ThreadContent;
          return getHighlightIndexes(highlight, text);
        });
      }

      const filteredSuggestedHighlights = suggestedHighlights.filter(
        ({ Start, End, QuestionId }) => {
          if (Start === -1) {
            return false;
          }

          let highlightsForParticularQuestion;

          if (QuestionId) {
            highlightsForParticularQuestion = highlights.filter(
              (highlight) => highlight.QuestionId === QuestionId,
            );
          } else {
            highlightsForParticularQuestion = highlights;
          }

          return !highlightsForParticularQuestion.some(
            (highlight) =>
              (Start < highlight.Start && End > highlight.Start) ||
              (Start > highlight.Start && Start < highlight.End) ||
              (End > highlight.Start && End < highlight.End) ||
              Start === highlight.Start ||
              End === highlight.End,
          );
        },
      );

      const sortedSuggestedHighlights = filteredSuggestedHighlights.sort(
        (a, b) => a.Start - b.Start,
      );

      setSuggestedHighlights(sortedSuggestedHighlights);
    }
  }, [activityType, data, activityDetails, post, highlights]);

  const getHighlightIndexes = (highlight: SuggestedHighlight, postText: string) => {
    let startIndex = postText.indexOf(highlight.Sentences[0].Text);
    let endIndex = 0;

    highlight.Sentences.forEach(({ Text }) => {
      const textIndex = postText.indexOf(Text);
      const postContainsSuggestedHighlight = textIndex !== -1;
      if (postContainsSuggestedHighlight) {
        startIndex = Math.min(startIndex, textIndex);
        endIndex = Math.max(endIndex, textIndex + Text.length);
      }
    });

    return {
      ...highlight,
      Start: startIndex,
      End: endIndex,
      HighlightText: postText.slice(startIndex, endIndex),
    };
  };

  return (
    <div className={styles.expandablePanels}>
      {isLoading ? (
        <Loading noOffset />
      ) : (
        <>
          {!!suggestedHighlights.length && (
            <SuggestedHighlights
              post={post}
              setPostHighlights={setPostHighlights}
              setSuggestedHighlights={setSuggestedHighlights}
              suggestedHighlights={suggestedHighlights}
            />
          )}
          <Highlights highlights={highlights} post={post} setPostHighlights={setPostHighlights} />
          <Notes post={post} />
        </>
      )}
    </div>
  );
};
