import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivityPost } from '@/types';

const initialState: ActivityPost[] = [];

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(_, { payload }: PayloadAction<ActivityPost[]>) {
      return payload;
    },
    updatePost(state, { payload: targetPost }: PayloadAction<ActivityPost>) {
      return state.map((post) =>
        post.TopicThreadId === targetPost.TopicThreadId ? targetPost : post,
      );
    },
    deletePost(state, { payload: targetPost }: PayloadAction<ActivityPost>) {
      return state.filter((post) => post.TopicThreadId !== targetPost.TopicThreadId);
    },
    addPost(state, { payload: targetPost }: PayloadAction<ActivityPost>) {
      return state.concat(targetPost);
    },
  },
});
