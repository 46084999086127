import { useEffect, useMemo, useState } from 'react';
import { toArray } from 'react-emoji-render';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, IconButton, Modal } from '@mui/material';
import cn from 'classnames';

import { ColorButton } from '@/components/topSection/colorButton';
import { useAppSelector } from '@/hooks';
import { ThemeBadge } from '@/pages/activities/activityPosts/expandablePanels/themeBadge';
import { Tags } from '@/pages/activities/activityPosts/tags';
import { useCreateNewHighlightMutation } from '@/store/api/highlights';
import { Color, PostHighlight, PostHighlightInputBody, Theme } from '@/types';

import styles from './editHighlightModal.module.css';

interface Props {
  isOpen: boolean;
  highlight: PostHighlight;
  postId: string;
  onClose: () => void;
  onEdit: (highlight: PostHighlight) => void;
}

export const EditHighlightModal: React.FC<Props> = ({
  highlight,
  isOpen,
  onClose,
  onEdit,
  postId,
}) => {
  const { Start, End, Text, ThreadId, HighlightId } = highlight;
  const [textForReport, setTextForReport] = useState('');
  const [Note, setNote] = useState('');
  const [selectedColor, setSelectedColor] = useState<Color>({
    Color: '',
    CreateDate: '',
    EditDate: '',
    HighlightColorId: '',
    Title: '',
  });
  const [highlightTags, setHighlightTags] = useState(highlight.Tags);
  const [selectedThemes, setSelectedThemes] = useState<Theme[]>(highlight.DiscussionThemes);
  const discussionId = useAppSelector((state) => state.userSettings.DiscussionId);
  const colorsMap = useAppSelector((state) => state.topSection.colorsMap);
  const [editHighlight, { isLoading: isEditing }] = useCreateNewHighlightMutation();

  const highlightText = useMemo(() => {
    const emojisArray = toArray(Text);

    const nodeWithEmojis = emojisArray.reduce((previous, current: any) => {
      if (typeof current === 'string') {
        return previous + current;
      }
      return previous + current.props.children;
    }, '');

    return nodeWithEmojis?.toString() || '';
  }, [Text]);

  useEffect(() => {
    setSelectedColor(highlight.Color);
    setNote(highlight.Note || '');
    setTextForReport(highlight.TextForReport || highlightText);
    setHighlightTags(highlight.Tags);
  }, [highlight, highlightText]);

  const handleCloseModal = () => {
    setSelectedColor(highlight.Color);
    setNote(highlight.Note || '');
    onClose();
  };

  const handleEditHighlight = async () => {
    const TextForReport = textForReport === highlightText ? '' : textForReport;
    const body: PostHighlightInputBody = {
      HighlightId,
      Start,
      End,
      ThreadId,
      Text,
      'Color[HighlightColorId]': selectedColor.HighlightColorId,
      Note,
      TextForReport,
    };

    highlightTags.forEach(
      (tag, index) => (body[`Tags[${index}][ContentTagId]`] = tag.ContentTagId),
    );

    selectedThemes.forEach(
      (theme, index) =>
        (body[`DiscussionThemes[${index}][DiscussionThemeId]`] = theme.DiscussionThemeId),
    );

    const response = await editHighlight({ discussionId, threadId: ThreadId, body });

    if ('error' in response) {
      toast.error('Error: Unable to edit highlight. Please try again later.');
      return;
    }

    onEdit(response.data);
    onClose();
  };

  return (
    <Modal onClose={handleCloseModal} open={isOpen}>
      <Box className={styles.editHighlightModal}>
        <header className={styles.header}>
          <div className={styles.headerTitle}>Highlights</div>
          <IconButton className={styles.closeButton} onClick={handleCloseModal}>
            <CloseIcon className={styles.closeIcon} sx={{ stroke: 'black', strokeWidth: 2 }} />
          </IconButton>
        </header>
        <ThemeBadge selectedThemes={selectedThemes} setSelectedThemes={setSelectedThemes} />

        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>Original Text</div>
          <div className={cn(styles.originalText, styles.textField)}>{highlightText}</div>
        </div>
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>Text for report</div>
          <textarea
            className={cn(styles.textInput, styles.textField)}
            onChange={(event) => setTextForReport(event.target.value)}
            placeholder="Type some text"
            value={textForReport}
          />
        </div>
        <div className={styles.colors}>
          {colorsMap.map((color) => (
            <ColorButton
              active={selectedColor?.HighlightColorId === color.HighlightColorId}
              color={color}
              key={color.HighlightColorId}
              onClick={setSelectedColor}
            />
          ))}
        </div>
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>Note</div>
          <textarea
            className={cn(styles.textInput, styles.textField)}
            onChange={(event) => setNote(event.target.value)}
            placeholder="Add some note"
            value={Note}
          />
        </div>
        <Tags
          apiId={postId}
          context="thread"
          isEditHighlightTags
          onChange={setHighlightTags}
          tags={highlightTags}
        />
        <div className={styles.modalControls}>
          <Button className={styles.modalButton} onClick={handleCloseModal} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            className={styles.modalButton}
            loading={isEditing}
            onClick={handleEditHighlight}
            variant="contained"
          >
            Save
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};
