import styles from './numberInCircle.module.css';

interface Props {
  color?: string;
  colorHover?: string;
  number: number;
  backgroundColor: string;
  backgroundColorHover?: string;
  borderColor: string;
  borderColorHover?: string;
  isHovering?: boolean;
}

export const NumberInCircle: React.FC<Props> = ({
  number,
  backgroundColor,
  backgroundColorHover,
  borderColor,
  borderColorHover,
  color,
  colorHover,
  isHovering,
}) => {
  return (
    <div
      className={styles.numberInCircle}
      style={{
        backgroundColor: isHovering ? backgroundColorHover : backgroundColor,
        borderColor: isHovering ? borderColorHover : borderColor,
        color: isHovering ? colorHover : color,
      }}
    >
      {number}
    </div>
  );
};
